import { React, useState, useEffect } from "react";
import "../styles/ForgotPassword.css";
import { Link, useNavigate } from "react-router-dom";
import mainLogo from "../assets/mainLogo.png";
import tagline1 from "../assets/tagline1.png";
import tagline2 from "../assets/tagline2.png";
import tagline3 from "../assets/tagline3.png";
import tagline4 from "../assets/tagline4.png";
import tagline5 from "../assets/tagline5.png";
import tagline6 from "../assets/tagline6.png";
import tagline7 from "../assets/tagline7.png";
import tagline8 from "../assets/tagline8.png";
import axios from "axios";
//optimisations are welcomed to be refactored
const slideshowImages = [
  tagline1,
  tagline2,
  tagline3,
  tagline4,
  tagline5,
  tagline6,
  tagline7,
  tagline8,
];

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/forgot-password`,
        formData
      );
      navigate("/reset-password");
    } catch (e) {
      console.log(e);
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const changeImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % slideshowImages.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(changeImage, 2000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className='fp-container'>
      <div className='fp-left-half'>
        <div className='logo'>
          <a href='/'>
            <img src={mainLogo} alt='Logit' id='mainLogo' />
          </a>
        </div>
        <h1 id='fp-h1'>
          Welcome to,
          <br /> Logit <span id='for'>for</span> Everyone
        </h1>
        <h2 id='Where-we'>Where we...</h2>
        <div className='slideshow'>
          <img
            src={slideshowImages[currentImageIndex]}
            alt='Slideshow'
            className='slideshow-image'
          />
        </div>
      </div>
      <div className='fp-right-half'>
        <div id='fp-p'>
          <h2>
            Forgot Password <br />
            <span id='fp-span'>
              relax we are here to recover your password and data
            </span>
          </h2>
        </div>
        <form onSubmit={handleSubmit} className='fp-form'>
          <div className='fp-form-group'>
            <label htmlFor='fp-emailInput' id='Email' className='fp-label'>
              Email
            </label>
            <input
              type='email'
              name='email'
              id='fp-emailInput'
              placeholder='Please Enter Your Email'
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type='submit' id='fp-button'>
            Recover my password
          </button>
        </form>
        <div className='login-link'>
          Didn't forget your password? <Link to='/login'>Login </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import services_attendanceDetails from "../assets/services_attendanceDetails.png";
import services_feesCollection from "../assets/services_feesCollection.png";
import services_feesDetails from "../assets/services_feesDetails.png";
import services_membersDetails from "../assets/services_membersDetails.png";
import services_notices from "../assets/services_notices.png";
import services_faq from "../assets/faq.png"

export const details = [
  {
    logo: services_attendanceDetails,
    DashboardInformation: "Attendance Information",
    link: "/attendance",
  },
  {
    logo: services_membersDetails,
    DashboardInformation: "Members Information",
    link: "/members-information",
  },
  {
    logo: services_feesDetails,
    DashboardInformation: "Fees Information",
    link: "/fee-information",
  },
  {
    logo: services_notices,
    DashboardInformation: "Notice Information",
    link: "/notice-information",
  },
  {
    logo: services_feesCollection,
    DashboardInformation: "Fees Collection Information",
    link: "/fee-collection-information",
  },
  {
    logo: services_faq,
    DashboardInformation: "FAQ",
    link: "/faq"
  }
];

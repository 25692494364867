import React, { useState } from "react";
import axios from "axios";
import DustbinIcon from "../assets/DustbinIcon.png";
import "../styles/NoticeInformation.css";
import ConfirmationDialog from "./ConfirmationPrompt";

const NoticeCard = ({
  issueDate,
  effectiveDate,
  details,
  onDelete,
  noticeId,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleDelete = async () => {
    try {
      const noticeIdentifier = { issueDate, effectiveDate, details };

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/gym/delete-gym-notice/${noticeId}`,
        {
          data: noticeIdentifier,
        }
      );

      onDelete(noticeIdentifier);
    } catch (error) {
      console.error("Error deleting notice:", error);
    }
    handleCloseConfirmation();
  };

  return (
    <div className="Notice-card">
      <div className="NoticeCard-main">
        <span id="NoticeCard-id">{issueDate}</span>
        <span id="NoticeCard-ed">{effectiveDate}</span>
        <p id="NoticeCard-para">{details}</p>
        <img
          src={DustbinIcon}
          alt="Delete"
          onClick={handleOpenConfirmation}
          id="NoticeCard-delete"
        />
      </div>
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={handleDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this notice?"
      />
    </div>
  );
};

export default NoticeCard;

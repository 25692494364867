import React, { useState, useEffect, useCallback } from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import MemberAttendanceCard from "../MemberComponents/MemberAttendanceCard";
import "../MemberStyles/MemberAttendance.css";
import qrScanner from "../Assets/qrScanner.png";
import Html5QrcodePlugin from "../MemberComponents/html5-qrCode";
import MemberDashboardComponent from "../MemberComponents/MemberDashboardComponent";
import MemberSevenDaysDistribution from "../MemberComponents/attendanceDistribution";

const MemberAttendance = () => {
  const navigate = useNavigate();
  const { memberId } = useParams();
  const [attendanceData, setAttendanceData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [attendancePercentage, setAttendancePercentage] = useState(0);
  const [benchmarkAttendance] = useState(
    (Math.round((Math.random() * (0.2 - 0.5) + 0.5) * 100) / 100).toFixed(2)
  );
  const [showScanner, setShowScanner] = useState(false);
  const [graphData, setGraphData] = useState("");

  const fetchMemberGraphSevenDayAttendanceData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/gym/member-graph-seven-day-attendance/${memberId}`
    );
    setGraphData(res.data);
  };

  const fetchAttendanceData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-member-attendance/${memberId}`
      );
      setAttendanceData(response.data.memberAttendances.reverse());
      setButtonDisable();
      setLoading(false);
      console.log("mounted");
      const totalAttendanceCount = response.data.memberAttendances.length;
      const totalDaysInMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1
      ).getDate();
      const currentAttendancePercentage =
        totalAttendanceCount / totalDaysInMonth;
      setAttendancePercentage(currentAttendancePercentage);

      const lastAttendanceTimestamp = new Date(
        response.data.memberAttendances[0].createdAt
      ).toLocaleDateString();
      const todayDate = new Date().toLocaleDateString();
      setButtonDisable(lastAttendanceTimestamp === todayDate);
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAttendanceData();
    fetchMemberGraphSevenDayAttendanceData();
  }, []);

  const markAttendance = async () => {
    try {
      if (!showScanner) {
        navigate(`/member/qrScan/${memberId}`);
      }
      // Handle attendance marking logic here
    } catch (error) {
      console.error("Error marking attendance: ", error);
    }
  };
  console.log(attendanceData);
  return (
    <>
      <MemberDashboardComponent memberId={memberId} />

      <div className="member-Attendance-container">
        <div className="member-Attendance-headers">
          <div className="member-Attendance-header">
            <h2 id="member-Attendance-header-title">Attendance</h2>
          </div>
          <button
            disabled={buttonDisable}
            className="member-Attendance-qrScanner"
            onClick={markAttendance}
          >
            <img
              src={qrScanner}
              alt="qrScanner"
              id="member-attendance-qrScanner-logo"
            />
            <span id="qrScannerSpan">Mark Attendance</span>
          </button>
        </div>

        <div className="member-AttendanceCards-container">
        <div className='member-Attendance-list'>
            <span id="mb-attendance-list-name">Name</span>
            <span id="mb-attendance-list-id">ID</span>
            <span id="mb-attendance-list-time">Time</span>
            <span id="mb-attendance-list-day">Day</span>
            <span id="mb-attendance-list-date">Date</span>
          </div>
          {loading ? (
            <PacmanLoader
              color="#3D2952"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : attendanceData.length === 0 ? (
            <p id="mb-no-attendance">No attendance yet</p>
          ) : (
            <div className="member-Attendance-list-main">
              {attendanceData.map((attendance, index) => (
                <MemberAttendanceCard
                  key={index}
                  name={attendance.name}
                  id={attendance.id}
                  time={new Date(attendance.updatedAt).toLocaleTimeString()}
                  day={new Date(attendance.updatedAt).toLocaleDateString(
                    "en-US",
                    { weekday: "long" }
                  )}
                  date={new Date(attendance.updatedAt).toLocaleDateString(
                    "en-US"
                  )}
                />
              ))}
            </div>
          )}
        </div>

        <div className="member-Attendance-statistics">
          <h2 id="member-Attendance-statistics-header">
            Attendance Statistics
          </h2>
          <div className="member-Attendance-graphs">
            <div className="member-graph-7day-attendance">
              <MemberSevenDaysDistribution data={graphData} />
            </div>
          </div>
        </div>

        <div className="member-Attendance-footer">
          {attendancePercentage >= benchmarkAttendance
            ? `Your attendance is higher than ${
                benchmarkAttendance * 100
              }% of members in your gym.`
            : `Your attendance is higher than ${
                benchmarkAttendance * 100
              }% of members in your gym.`}
        </div>
      </div>
    </>
  );
};

export default MemberAttendance;

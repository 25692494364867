import React, { useState, useEffect, cloneElement } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import MemberPaymentInformationCard from "../MemberComponents/MemberPaymentInformationCard";
import "../MemberStyles/MemberPaymentInformation.css";
import MemberDashboardComponent from "../MemberComponents/MemberDashboardComponent";

const MemberPaymentInformation = () => {
  const { memberId } = useParams();
  const [collections, setCollections] = useState([]);
  const [CollectionLength, setCollectionLength] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFeeCollection = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-member-collection/${memberId}`
      );
      setCollections(response.data.collections);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
    fetchAllCollections();
  };
  const fetchAllCollections = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-gym-collection/`
      );
      console.log("allCOle", response.data.flattenedCollection.length);
      setCollectionLength(response.data.flattenedCollection.length);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  useEffect(() => {
    fetchFeeCollection();
  }, []);
  console.log(collections);
  return (
    <>
      <MemberDashboardComponent memberId={memberId} />
      <div>
        <h2 id="member-PaymentInformation-header-title">Fees Collection</h2>
        <div className="member-PaymentInformation-container">
          <div className="member-PaymentInformation-row">
            <span id="member-PaymentInformation-amount">Amount</span>
            <span id="member-PaymentInformation-date">Date</span>
            <span id="member-PaymentInformation-time">Time</span>
            <span id="member-PaymentInformation-mode">Mode</span>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : collections.length > 0 ? (
            <div className="member-PaymentInformation-main">
              {collections.map((collection, index) => (
                <MemberPaymentInformationCard
                  key={index}
                  amount={collection.amount}
                  date={collection.date}
                  time={new Date(collection.createdAt).toLocaleTimeString()}
                  mode={collection.mode}
                />
              ))}
            </div>
          ) : (
            <p>No data present</p>
          )}
        </div>
        <div className="member-PaymentInformation-footer">
          <h2 id="member-PaymentInformation-footer-h2">Payment Statistics</h2>
          <p id="member-PaymentInformation-footer-main">
            --Your payment records are regular than{" "}
            {(collections.length / CollectionLength) * 100}% members of your
            gym.
          </p>
        </div>
      </div>
    </>
  );
};

export default MemberPaymentInformation;

import React from "react";
import { Link } from "react-router-dom";

const DashboardCard = ({ DashboardLogo, DashboardInformation, link }) => {
  return (
    <div className="DashboardCard-main">
      <div className="DashboardCard-logo">
        <img src={DashboardLogo} alt="DashboardLogo" id="Dashboard-logo-id" />
      </div>
      <Link to={link} style={{ textDecoration: "none" }}>
        <div className="DashboardCard-Information">{DashboardInformation}</div>
      </Link>
    </div>
  );
};

export default DashboardCard;

import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "../styles/Error.css";

const Error = () => {
  const { error, errorInformation } = useParams();
  const errorToDisplay = error || "404";
  const errorInfo = errorInformation || "Page Not Found";

  return (
    <div className='error-page'>
      <img className='background-image' alt='' src='/rectangle-2.svg' />
      <img className='logo' alt='' src='/logit_logo_22-removebg-preview.png' />
      <div className='error-code-container'>
        {errorToDisplay.split("").map((char, index) => (
          <div key={index} className='error-code'>
            {char}
          </div>
        ))}
      </div>
      <img
        className='stars-background'
        alt=''
        src='/stars-with-glow-effects@2x.png'
      />
      <div className='message'>{errorInfo}</div>
      <Link className='back-to-home' to='/'>
        Back to Home
      </Link>
    </div>
  );
};

export default Error;
import React from "react";
import "../MemberStyles/MemberHome.css";
import { Element } from "react-scroll";
import MemberNavbar from "../MemberComponents/MemberNavbar";
import MemberHomeText from "../MemberComponents/MemberHomeText";
import MemberServices from "../MemberComponents/MemberServices";
import MemberAboutUs from "../MemberComponents/MemberAboutUs";
import MemberConnect from "../MemberComponents/MemberConnect";

const MemberHome = () => {
  return (
    <div>
      <MemberNavbar />
      <Element name="home">
        <MemberHomeText />
      </Element>
      <Element name="services">
        <MemberServices />
      </Element>
      <Element name="about">
        <MemberAboutUs />
      </Element>
      <Element name="connect">
        <MemberConnect />
      </Element>
    </div>
  );
};

export default MemberHome;

import React, { useState, useEffect } from "react";
import services_attendanceDetails from "../assets/services_attendanceDetails.png";
import services_feesCollection from "../assets/services_feesCollection.png";
import services_feesDetails from "../assets/services_feesDetails.png";
import services_membersDetails from "../assets/services_membersDetails.png";
import services_notices from "../assets/services_notices.png";
import services_stats from "../assets/services_stats.png";
import ServicesCard from "./ServicesCard";

const details = [
  {
    logo: services_membersDetails,
    title: "Members Details",
    description:
      "We help you in effortlessly organizing and accessing your member records",
  },
  {
    logo: services_attendanceDetails,
    title: "Attendance Details",
    description:
      "We help you in recording, storing and accessing attendance record of all members",
  },
  {
    logo: services_feesDetails,
    title: "Fees Details",
    description:
      "We manage fees details for all members of your gym with our specially designed tools",
  },
  {
    logo: services_feesCollection,
    title: "Fees Collection",
    description:
      "We securely store all the fees details for you so you don't have to manage multiple books",
  },
  {
    logo: services_notices,
    title: "Notices",
    description:
      "We help you in sending important information to all the members of your gym",
  },
  {
    logo: services_stats,
    title: "Statistics",
    description:
      "We show you multiple stats about your gym to help you make better decisions.",
  },
];

const Services = () => {
  return (
    <>
      <div className="serviceCard-header">How We Help Gym Owners</div>
      <div className="ServiceCards">
        {details.map((detail, index) => (
          <ServicesCard
            key={index}
            logo={detail.logo}
            title={detail.title}
            description={detail.description}
          />
        ))}
      </div>
      <div className="serviceCard-footer1">
        & many more once you partner with us
      </div>
    </>
  );
};

export default Services;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import services_attendanceDetails from "../Assets/services_attendanceDetails.png";
import services_membersDetails from "../Assets/services_membersDetails.png";
import services_notices from "../Assets/services_notices.png";
import services_paymentDetails from "../Assets/services_paymentDetails.png";
import QrScanner2 from "../Assets/QrScanner2.png";
import "../MemberStyles/MemberDashboard.css";
import Member from "../Assets/Member.png";
import ErrorPage from "../MemberPages/Error";
import MemberDashboardCard from "../MemberPages/MemberDashboardCard";

const details = [
  {
    logo: QrScanner2,
    DashboardInformation: "Mark Attendance",
    link: `/member/qrScan`,
  },
  {
    logo: services_attendanceDetails,
    DashboardInformation: "Attendance Information",
    link: "/member/attendance",
  },
  {
    logo: services_paymentDetails,
    DashboardInformation: "Payment Information",
    link: "/member/payment",
  },
  {
    logo: services_notices,
    DashboardInformation: "Notice Information",
    link: "/member/notice",
  },
  {
    logo: services_membersDetails,
    DashboardInformation: "Complete Profile",
    link: "/member/profile",
  },
];

const MemberDashboardComponent = ({ memberId }) => {
  const [member, setMember] = useState(null);
  const [gymInfo, setGymInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentDate] = useState(new Date());

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/gym//show-member-by-id/${memberId}`
        );
        console.log(res);
        setMember(res.data.member);
        setLoading(false);
      } catch (e) {
        setError(true);
        setLoading(false);
        console.error(e);
      }
    };
    fetchMemberData();
  }, [memberId]);

  useEffect(() => {
    const fetchGymInfo = async () => {
      try {
        if (member && member.gymId) {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/gym/show-gym-by-id/${member.gymId}`
          );
          setGymInfo(res.data.gym);
        }
      } catch (e) {
        console.error(e);
      }
    };
    if (member) {
      fetchGymInfo();
    }
  }, [member]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorPage />;
  }

  const dueDate = findDueDate(member.endDate);

  return (
    <>
      <div className="member-Dashboard-navbar">
        <div className="member-Dashboard-navtop">
          <h1 id="member-Dashboard-navtop-h1"> Dashboard </h1>
          <Link to="/member/login" id="member-Dashboard-navtop-logout">
            Logout
          </Link>
        </div>
        <div className="member-Dashboard-navmiddle">
          <img
            src={member.displayImage}
            alt="Gym Logo"
            id="member-Dashboard-navmiddle-img"
          />
          <div className="member-Dashboard-navmiddle-texts">
            <h1 id="member-Dashboard-navmiddle-h1">
              {member ? member.name : "Member Name"}
            </h1>
            <span id="member-Dashboard-navmiddle-span">
              {gymInfo ? gymInfo.name : "Gym Name"}
            </span>
          </div>
        </div>
        <div className="member-Dashboard-navbottom"></div>
      </div>
      {currentDate <= dueDate &&
        currentDate >= new Date(member.endDate) - 3 * 24 * 60 * 60 * 1000 && (
          <div className="member-Dashboard-fee-middle">
            <h2 id="member-Dashboard-fee-middle-h2">Fees Information</h2>
            <div className="member-Dashboard-fee-middle-information">
              Fees will be dues in 3 days. Your membership will expire on{" "}
              {member ? member.endDate : "31-01-2023"}. Pay your fees by{" "}
              {dueDate.toDateString()} to avoid interruptions. Have a good day
              you gym freak.
            </div>
          </div>
        )}
      <div className="member-Dashboard-middle">
        {details.map((detail, index) => (
          <MemberDashboardCard
            key={index}
            DashboardLogo={detail.logo}
            DashboardInformation={detail.DashboardInformation}
            link={detail.link}
            memberId={member._id}
          />
        ))}
      </div>
    </>
  );
};

export default MemberDashboardComponent;

function findDueDate(endDate) {
  const dueDate = new Date(endDate);
  dueDate.setDate(dueDate.getDate() - 3);
  return dueDate;
}
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Member from "../Assets/Member.png";
import mt1 from "../Assets/mt1.png";
import mt2 from "../Assets/mt2.png";
import mt3 from "../Assets/mt3.png";
import mt4 from "../Assets/mt4.png";
import "../MemberStyles/MemberSignUp.css";

const slideshowImages = [mt1, mt2, mt3, mt4];

const MemberSignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, formData)
        .then((res) => {
          return navigate(`/member/login/`);
        });
      toast.success("User Signed Up!", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    } catch (error) {
      setError(error);
      toast.error("Error submitting data", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const changeImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % slideshowImages.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(changeImage, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="member-signup-container">
      <div className="member-signup-left-half">
        <div className="member-signup-logo">
          <a href="/member">
            <img src={Member} alt="Logit" id="member-signup-mainLogo" />
          </a>
        </div>
        <h1 id="member-signup-h1">
          Welcome to,
          <br /> Logit <span id="member-signup-for">for</span> Business
        </h1>
        <h2 id="member-signup-Where-we">Where we...</h2>
        <div className="member-signup-slideshow">
          <img
            src={slideshowImages[currentImageIndex]}
            alt="Slideshow"
            className="member-signup-slideshow-image"
          />
        </div>
      </div>
      <div className="member-signup-right-half">
        <div className="member-signup-right-half-top">
          <h2 id="member-signup-right-half-h2">
            Sign Up <br />
            <p className="member-signup-b-text">
              and shake hands with your gym management partner
            </p>
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="member-signup-form">
          <div className="member-signup-form-group">
            <label htmlFor="signup-nameInput" className="member-signup-label">
              Name
            </label>
            <input
              type="text"
              name="username"
              id="member-signup-nameInput"
              placeholder="Please Enter Your Name"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="member-signup-form-group">
            <label htmlFor="signup-emailInput" className="member-signup-label">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="member-signup-emailInput"
              placeholder="Please Enter Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="member-signup-form-group">
            <label
              htmlFor="signup-passwordInput"
              className="member-signup-label"
            >
              Password
            </label>
            <div className="member-signup-password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="member-signup-passwordInput"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <i
                className={`${showPassword ? "fa fa-eye-slash" : "fa fa-eye"
                  } password-icon`}
                onClick={() => setShowPassword(!showPassword)}
              ></i>
            </div>
          </div>
          <button type="submit" id="member-signup-button">
            Sign Up
          </button>
        </form>
        <div className="member-login-link">
          Already registered? <Link to="/member/login">Login </Link>
        </div>
      </div>
    </div>
  );
};

export default MemberSignUp;

import React from "react";
import ProfileCard from "./ProfileCard";
import { details } from "../lib/constants";

const AboutUs = () => {
  return (
    <>
      <div>
        <iframe
          src="https://www.youtube.com/embed/bq2xpiRl8U4?si=d7_05bNiNTzainSx"
          frameborder="0"
          allow="autoplay; encrypted-media"
          id="iframe"
          allowfullscreen
          title="logit video"
        />
        <h1 id="AboutUs-h1">Who are we</h1>
        <div className="AboutUs-Cards">
          {details.map((detail, index) => (
            <ProfileCard
              key={index}
              ePic={detail.profilePic}
              name={detail.name}
              description={detail.description}
            />
          ))}
        </div>
        <div className="AboutUs-bottom">
          <span className="AboutUs-visionAim">Our Vision</span>
          <p className="AboutUs-para">
            We want to make running gyms easy for owners.
            <br />
            Our Vision is to provide gym owners with tools
            <br />
            that simplify everything, so they can pull all their <br />
            energy into training members and making their <br />
            gyms fantastic. We dream of a world where gym
            <br />
            owners can focus on what they love, knowing
            <br />
            that the rest is effortlessly taken care of.
          </p>
        </div>
        <div className="AboutUs-bottom">
          <span className="AboutUs-visionAim">Our Aim</span>
          <p className="AboutUs-para1">
            Our aim is to create user-friendly gym
            <br />
            management software that takes the hassle out
            <br />
            of running a gym. We aim to have more and more
            <br />
            gym owners use our software to make their lives
            <br />
            easier. This way, they can dedicate themselves to
            <br />
            member training and improving their gyms
            <br />
            without any worries.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { auth, provider } from "../../firebase/config";
import { signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";
import "../MemberStyles/MemberLogin.css";
import Member from "../Assets/Member.png";
import mt1 from "../Assets/mt1.png";
import mt2 from "../Assets/mt2.png";
import mt3 from "../Assets/mt3.png";
import mt4 from "../Assets/mt4.png";
import Logo from "../Assets/Google__G__Logo.svg";

const slideshowImages = [mt1, mt2, mt3, mt4];

function MemberLogin() {
  const navigate = useNavigate();

  // const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [, setError] = useState(null);

  const changeImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % slideshowImages.length
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/signin`, formData)
        .then((res) => {
          return navigate(`/member/dashboard/${res.data.rest.email}`);
        });
      toast.success("Logged In ", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    } catch (error) {
      setError(error);
      console.error("Login failed:", error);
      toast.error("Login Failed", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(changeImage, 2000);
    return () => clearInterval(intervalId);
  }, []);


  const registerWithGoogle = async () => {
    try {
      const data = await signInWithPopup(auth, provider);

      handleSubmitforGoogle({
        email: data.user.email,
        password: data.user.uid,
      });
    } catch (err) {
      console.log("haw oAuth Error", err);
    }
  };

  const handleSubmitforGoogle = async (formData) => {
    // authentication logic
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signin`,
        formData
      );
      if (response.status === 200) {
        toast.success("Logged In ", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: false,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Login Failed", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    }
  };

  return (
    <div className="memberlogin-container">
      <div className="member-left-half">
        <div className="memberlogo">
          <a href="/member">
            <img src={Member} alt="Logit" id="mb-mainLogo" />
          </a>
        </div>
        <h1 id="mb-left-half-h1">
          Welcome to,
          <br /> Logit <span id="mb-for">for</span> Everyone
        </h1>
        <h2 id="mb-Where-we">Where we...</h2>
        <div className="mb-login-slideshow">
          <img
            src={slideshowImages[currentImageIndex]}
            alt="Slideshow"
            className="mb-slideshow-image"
          />
        </div>
      </div>
      <div className="member-right-half">
        <div className="mb-right-half-top">
          <h2 className="memberlogin-h2">
            Login <br />
            <p className="memberlogin-h3">
              to change your gym management experience forever
            </p>
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="mb-form">
          <div className="mb-login-form-group">
            <label htmlFor="mb-login-emailInput" className="mb-label">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="mb-login-emailInput"
              placeholder="Please Enter Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-login-form-group">
            <label htmlFor="mb-login-passwordInput" className="mb-label">
              Password
            </label>
            <div className="mb-login-password-input">
              <input
                type={"password"}
                name="password"
                id="mb-login-passwordInput"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <button type="submit" id="mb-login-submit">
            Login
          </button>
          <span>
            <a href="/forgotPassword" id="mb-login-forgot-pswd">
              {" "}
              Forgot Password?
            </a>
          </span>
          <br />
          <span id="mb-login-Or">
            Or
          </span>
          <br />
          <button onClick={registerWithGoogle} id="mb-login-googleSignin">
            <img src={Logo} alt="Google Logo" id="mb-login-googleLogo" />{" "}
            <span id="mb-login-Google-text">Continue with Google</span>
          </button>
        </form>
        <div className="mb-login-signup-link">
          Not registered? <Link to="/member/signup">Create an account</Link>
        </div>
      </div>
    </div>
  );
}

export default MemberLogin;

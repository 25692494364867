import React, { useEffect, useState } from "react";
import "../styles/Attendance.css";
import axios from "axios";

const AttendanceCard = ({ imageSrc = "", name, id, time, day, date }) => {
  const [member, setMember] = useState();

  useEffect(() => {
    const fetchMem = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-member-by-id/${imageSrc}`
      );
      setMember(res.data.member);
    };
    fetchMem();
  });

  return (
    <div className="attendance-card">
      <div className="attendanceCard-main">
        <div className="attendanceCard-img">
          <img
            src={member ? member.displayImage : ""}
            alt={name}
            className="attendanceCard-image"
          />
        </div>
        <span id="attendanceCard-name"> {name}</span>
        <span id="attendanceCard-id"> {id}</span>
        <span id="attendanceCard-time"> {time}</span>
        <span id="attendanceCard-day">{day}</span>
        <span id="attendanceCard-date"> {date}</span>
      </div>
    </div>
  );
};

export default AttendanceCard;

import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import IndividualMemberPaymentCard from "./IndividualMemberPaymentCard";
import "../styles/IndividualMemberPayment.css";
import MemberDashboardOwnerInfo from "./MemberDashboardOwnerInfo";

const IndividualMemberPayment = () => {
  const { memId } = useParams();
  const [payments, setPayments] = useState([]);
  const fetchPaymentData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-member-collection/${memId}`
      );
      setPayments(response.data.collections);
    } catch (error) {
      console.log("Error fetching payment:", error);
    }
  };

  useEffect(() => {
    fetchPaymentData();
  }, []);

  return (
    <>
    <MemberDashboardOwnerInfo memid={memId}/>
      <div className='imp-container'>
        <div className='imp-row'>
          <span id='imp-row-amount'> Amount </span>
          <span id='imp-row-date'> Date </span>
          <span id='imp-row-time'> Time </span>
          <span id='imp-row-mode'> Mode </span>
        </div>
        <div className='imp-main'>
          {payments && payments.length > 0 ? (
            payments.map((payment, index) => (
              <IndividualMemberPaymentCard
                key={index}
                amount={payment.amount}
                date={payment.date}
                time={new Date(payment.createdAt).toLocaleTimeString()}
                mode={payment.mode}
              />
            ))
          ) : (
            <p>No payment records</p>
          )}
        </div>
      </div>
    </>
  );
};

export default IndividualMemberPayment;

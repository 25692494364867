import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const AgeDistribution = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (data) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");

      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: Object.keys(data),
          datasets: [
            {
              label: "Age Distribution",
              data: Object.values(data),
              backgroundColor: "#1a293c",
              borderColor: "#ffffff",
              borderWidth: 1,
            },
          ],
        },
        options: {
          indexAxis: "x",
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default AgeDistribution;

import React, { useState } from "react";
import "../styles/GymSetup.css";
import { Navigate } from "react-router-dom";
import imageUploadIcon from "../assets/imageUploadIcon.png";
// import preloadedImage from "../assets/preloadedImage.png"
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import mainLogo from "../assets/mainLogo.png";
import QRCode from "qrcode.react";
import { saveAs } from "file-saver";
import axios from "axios";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import Loading from "../components/Loading";

const GymSetup = () => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    district: "",
    pincode: "",
    fees: {
      monthlyFees: 0,
      quarterlyFees: 0,
      halfYearlyFees: 0,
      yearlyFees: 0,
    },
    timming: {
      morningOpen: "",
      morningClose: "",
      eveningOpen: "",
      eveningClose: "",
    },
    gender: "",
    description: "",
    instagramId: "",
    displayImage: "",
    preloadedImage: null,
    selectedImages: [],
    previewImages: [""],
    ownerDetails: {
      name: "",
      contact: "",
      designation: "",
    },
    qrCode: "",
  });

  const [loading, setLoading] = useState(false);
  const [redirectToGymSetup, setRedirectToGymSetup] = useState(false);

  const handleImageClick = () => {
    document.getElementById("Gym-image").click();
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`GymImage-${selectedImage.name}`);

      fileRef.put(selectedImage).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL);
          setFormData({ ...formData, displayImage: downloadURL });
        });
      }).catch(error => {
        console.error("Error uploading image:", error);
        toast.error("Error uploading image");
      });
    } else {
      setFormData({ ...formData, displayImage: "Gym Image not uploaded" });
    }
  };

  const handleImageUploadClick = () => {
    document.getElementById("GymSetup-images").click();
  };

  function generateRandomString(length) {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      randomString += chars[randomIndex];
    }

    return randomString;
  }

  const handleGenerateQRCode = () => {
    setLoading(true)
    if (
      !formData.displayImage ||
      !formData.name ||
      !formData.address ||
      !formData.district ||
      !formData.pincode ||
      !formData.fees ||
      !formData.ownerDetails
    ) {
      toast.warning("Please fill in all required fields.");
      return;
    }
    const generatedQRCodeData = generateRandomString(20);

    setFormData({ ...formData, qrCode: generatedQRCodeData });
    setLoading(false)
  };

  const handleDownloadQRCode = () => {
    setLoading(true)
    const canvas = document.getElementById("GymSetup-qr-code-canvas");
    canvas.toBlob((blob) => {
      saveAs(blob, "gym_qr_code.png");
    });
    setLoading(false)
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/gym/create-gym`,
        formData
      );
      console.log(response.data.message);
      setLoading(false);
      setRedirectToGymSetup(true);
    } catch (error) {
      console.error("Error submitting gym data:", error);
      setLoading(false);
      toast.error("Error submitting gym data");
    }
  };

  if (redirectToGymSetup) {
    return <Navigate to='/dashboard' />;
  }
  return (
    <div className='GymSetup-main'>
      <div className='GymSetup-nav'>
        <h1 id='GymSetup-heading'>
          Setup your gym <br /> <span id='GymSetup-heading-span'> and </span>
          <p id='GymSetup-heading-p'>
            Start your gym management journey with us
          </p>
        </h1>
      </div>
      <h2 id='GymSetup-title'>Step 1 : Gym Details</h2>
      <form className='GymSetup-form' onSubmit={handleFormSubmit}>
        <div className='GymSetup-img'>
          <div className='GymSetup-imgdiv' onClick={handleImageClick}>
            {formData.displayImage ? (
              <img
                src={formData.displayImage}
                alt='Gym Logo'
                className='GymSetup-img-preview'
              />
            ) : (
              //  : preloadedImage ? (
              //   <img
              //     src={preloadedImage}
              //     alt='Preloaded Gym Logo'
              //     className='GymSetup-img-preview'
              //   />
              // )
              <img
                src={imageUploadIcon}
                alt='Upload Icon'
                className='GymSetup-img-upload-icon'
              />
            )}
          </div>
          <input
            type='file'
            accept='image/*'
            id='Gym-image'
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
        <div className='GymSetup-input-group-name'>
          <label htmlFor='GymSetup-name' id='GymSetup-nameSpan'>
            Name
          </label>
          <input
            type='text'
            id='GymSetup-name'
            placeholder='Enter a name'
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
        </div>
        <div className='GymSetup-input-group-address'>
          <label htmlFor='GymSetup-address' id='GymSetup-addressSpan'>
            Address
          </label>
          <input
            type='text'
            id='GymSetup-address'
            placeholder='Enter an address'
            value={formData.address}
            onChange={(e) =>
              setFormData({ ...formData, address: e.target.value })
            }
            required
          />
        </div>
        <div className='GymSetup-input-group-district'>
          <label htmlFor='GymSetup-district' id='GymSetup-districtSpan'>
            District
          </label>
          <input
            type='text'
            id='GymSetup-district'
            placeholder='Enter a district'
            value={formData.district}
            onChange={(e) =>
              setFormData({ ...formData, district: e.target.value })
            }
            required
          />
        </div>
        <div className='GymSetup-input-group-pincode'>
          <label htmlFor='GymSetup-pincode' id='GymSetup-pincodeSpan'>
            Pincode
          </label>
          <input
            type='text'
            id='GymSetup-pincode'
            placeholder='Enter a pincode'
            value={formData.pincode}
            onChange={(e) =>
              setFormData({ ...formData, pincode: e.target.value })
            }
            required
          />
        </div>
        <div className='GymSetup-input-group-fees'>
          <label htmlFor='GymSetup-monthlyfees' id='GymSetup-feesSpan'>
            Fees
          </label>
          <input
            type='number'
            id='GymSetup-monthlyfees'
            placeholder='Enter a monthly fees'
            value={formData.fees.monthly === 0 ? "" : formData.fees.monthly}
            onChange={(e) =>
              setFormData({
                ...formData,
                fees: { ...formData.fees, monthly: e.target.value },
              })
            }
            required
            min='0'
          />
        </div>
        <input
          type='number'
          id='GymSetup-quarterlyfees'
          placeholder='Enter a quarterly fees'
          value={formData.fees.quarterly === 0 ? "" : formData.fees.quarterly}
          onChange={(e) =>
            setFormData({
              ...formData,
              fees: { ...formData.fees, quarterly: e.target.value },
            })
          }
          required
          min='0'
        />
        <input
          type='number'
          id='GymSetup-halfYearlyfees'
          placeholder='Enter a half-yearly fees'
          value={formData.fees.halfYearly === 0 ? "" : formData.fees.halfYearly}
          onChange={(e) =>
            setFormData({
              ...formData,
              fees: { ...formData.fees, halfYearly: e.target.value },
            })
          }
          required
          min='0'
        />
        <input
          type='number'
          id='GymSetup-yearlyfees'
          placeholder='Enter a yearly fees'
          value={formData.fees.yearly === 0 ? "" : formData.fees.yearly}
          onChange={(e) =>
            setFormData({
              ...formData,
              fees: { ...formData.fees, yearly: e.target.value },
            })
          }
          required
          min='0'
        />
        <div className='GymSetup-input-group-timing'>
          <label htmlFor='GymSetup-morningOpeningtime' id='GymSetup-timingSpan'>
            Timing
          </label>
          <input
            type='time'
            id='GymSetup-morningOpeningtime'
            placeholder='Enter morning opening time'
            value={formData.timming.morningOpen}
            onChange={(e) =>
              setFormData({
                ...formData,
                timming: {
                  ...formData.timming,
                  morningOpen: e.target.value,
                },
              })
            }
            required
          />
          <br />
        </div>
        <input
          type='time'
          id='GymSetup-morningClosingtime'
          placeholder='Enter morning closing time'
          value={formData.timming.morningClose}
          onChange={(e) =>
            setFormData({
              ...formData,
              timming: {
                ...formData.timming,
                morningClose: e.target.value,
              },
            })
          }
          required
        />
        <br />
        <input
          type='time'
          id='GymSetup-eveningOpeningtime'
          placeholder='Enter evening opening time'
          value={formData.timming.eveningOpen}
          onChange={(e) =>
            setFormData({
              ...formData,
              timming: {
                ...formData.timming,
                eveningOpen: e.target.value,
              },
            })
          }
          required
        />
        <br />
        <input
          type='time'
          id='GymSetup-eveningClosingtime'
          placeholder='Enter evening closing time'
          value={formData.timming.eveningClose}
          onChange={(e) =>
            setFormData({
              ...formData,
              timming: {
                ...formData.timming,
                eveningClose: e.target.value,
              },
            })
          }
          required
        />
        <br />
        <div className='GymSetup-input-group-gender'>
          <label htmlFor='GymSetup-gender' id='GymSetup-genderSpan'>
            Allowed Gender
          </label>
          <select
            value={formData.gender}
            id='GymSetup-gender'
            onChange={(e) =>
              setFormData({ ...formData, gender: e.target.value })
            }
            required
          >
            <option value=''>Select Allowed Gender</option>
            <option value='male'>Male</option>
            <option value='female'>Female</option>
            <option value='both'>Unisex</option>
          </select>
        </div>
        <div className='GymSetup-input-group-description'>
          <label htmlFor='GymSetup-description' id='GymSetup-descriptionSpan'>
            Description
          </label>
          <textarea
            rows='8'
            cols='100'
            value={formData.description}
            id='GymSetup-description'
            placeholder='Enter a description'
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            required
          />
        </div>
        <div className='GymSetup-input-group-instagram'>
          <label htmlFor='GymSetup-instagram' id='GymSetup-instagramSpan'>
            Instagram ID
          </label>
          <input
            type='text'
            id='GymSetup-instagram'
            placeholder='Enter your Instagram id'
            value={formData.instagramId}
            onChange={(e) =>
              setFormData({ ...formData, instagramId: e.target.value })
            }
            required
          />
        </div>
        <h2 id='GymSetup-ownersDetails'>Step 2 : Owner Details </h2>
        <div className='GymSetup-input-group-owner_name'>
          <label htmlFor='GymSetup-owner_name' id='GymSetup-owner_nameSpan'>
            Name
          </label>
          <input
            type='text'
            id='GymSetup-owner_name'
            placeholder='Enter a name'
            value={formData.ownerDetails.name}
            onChange={(e) =>
              setFormData({
                ...formData,
                ownerDetails: {
                  ...formData.ownerDetails,
                  name: e.target.value,
                },
              })
            }
            required
          />
        </div>
        <div className='GymSetup-input-group-contact'>
          <label htmlFor='GymSetup-contact' id='GymSetup-contactSpan'>
            Contact
          </label>
          <PhoneInput
            id='GymSetup-contact'
            placeholder='Enter a contact number'
            value={formData.ownerDetails.contact}
            defaultCountry='IN'
            onChange={(value) =>
              setFormData({
                ...formData,
                ownerDetails: { ...formData.ownerDetails, contact: value },
              })
            }
            maxLength='11'
            minLength='11'
            required
          />
        </div>
        <div className='GymSetup-input-group-designation'>
          <label htmlFor='GymSetup-designation' id='GymSetup-designationSpan'>
            Designation
          </label>
          <input
            type='text'
            id='GymSetup-designation'
            placeholder='Enter a designation'
            value={formData.ownerDetails.designation}
            onChange={(e) =>
              setFormData({
                ...formData,
                ownerDetails: {
                  ...formData.ownerDetails,
                  designation: e.target.value,
                },
              })
            }
          />
        </div>
        <div>
          <button
            type='button'
            id='GymSetup-qrGenerateButton'
            onClick={handleGenerateQRCode}
          >
            Generate QR Code
          </button>
          {formData.qrCode && (
            <div className='GymSetup-qrCode'>
              <QRCode
                value={formData.qrCode}
                fgColor='#1a293c'
                bgColor='#ffffff'
                includeMargin={false}
                imageSettings={{
                  src: mainLogo,
                  height: 40,
                  width: 40,
                  excavate: true,
                }}
                size={200}
                id='GymSetup-qr-code-canvas'
              />
              {formData.qrCode && (
                <button
                  type='button'
                  id='GymSetup-downloadQRCode'
                  onClick={handleDownloadQRCode}
                >
                  Download QR Code
                </button>
              )}
            </div>
          )}
        </div>
        <div>
          {loading ? (
            <><Loading/></>
          ) : (
            <button type='submit' id='GymSetup-submitButton'>
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default GymSetup;

import React from 'react';

const Loading = () => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="#000" width="100" height="100">
    <circle cx="50" cy="50" r="0" fill="none" stroke="#000" strokeWidth="2">
      <animate attributeName="r" repeatCount="indefinite" dur="1.5s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline"/>
      <animate attributeName="opacity" repeatCount="indefinite" dur="1.5s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline"/>
    </circle>
    <circle cx="50" cy="50" r="0" fill="none" stroke="#000" strokeWidth="2">
      <animate attributeName="r" repeatCount="indefinite" dur="1.5s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.75s"/>
      <animate attributeName="opacity" repeatCount="indefinite" dur="1.5s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.75s"/>
    </circle>
  </svg>
);

export default Loading;

import React from "react";

const MemberServiceCard = ({ logo, title, description }) => {
  return (
    <div className="member-serviceCard-main">
      <div className="member-serviceCard-top">
        <img src={logo} alt="pic" id="member-serviceCard-img" />
      </div>
      <div className="member-serviceCard-middle">{title}</div>
      <div className="member-serviceCard-bottom">
        <p id="member-serviceCard-description">{description}</p>
      </div>
    </div>
  );
};

export default MemberServiceCard;

import React, { useState, useEffect, useCallback } from "react";
import FeesCollectionCard from "../components/FeesCollectionCard";
import axios from "axios";
import Dashboardpannel from "../components/Dashboardpannel";
import "../styles/FeesCollection.css";
import Dashboard from "./Dashboard";
import MonthlyDistributionChart from "../components/MonthlyDistributionChart";
import PaymentDistributionChart from "../components/PaymentDistributionChart";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
//optimisations
const FeesCollection = () => {
  axios.defaults.withCredentials = true;

  const [formData, setFormData] = useState({
    date: "",
    amount: "",
    mode: "",
    memberName: "",
    id: "",
  });
  const [loading, setLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [monthlyCollectionData, setMonthlyCollectionData] = useState({});
  const [paymentMethodData, setPaymentMethodData] = useState({});

  const fetchMonthlyCollectionData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/monthly-collection-distribution`
      );

      setMonthlyCollectionData(res.data.monthlyAmounts);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const fetchPaymentMethodData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/payment-method-distribution`
      );
      const charData = {
        card: res.data.card,
        cash: res.data.cash,
        upi: res.data.upi,
      };
      setPaymentMethodData(charData);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchFeeCollection();
    fetchMonthlyCollectionData();
    fetchPaymentMethodData();
  }, []);

  const fetchFeeCollection = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-gym-collection`
      );
      setCollections(response.data.flattenedCollection.reverse() || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching fee collections:", error);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { date, memberName, id, amount, mode } = formData;

    if (!date || !memberName || !id || !amount || !mode) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/gym/create-collection`,
        formData
      );

      fetchFeeCollection();
      setLoading(false);
      setFormData({
        date: "",
        amount: "",
        mode: "",
        memberName: "",
        id: "",
      });
      toast.success("Collection Added", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
      toast.error("Collection not added. Try Again.", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
      setLoading(false);
    }
  };
  return (
    <>
      <Dashboard />
      <div className='FeesCollectionConationer'>
        <div className='FeesCollection-header'>
          <h2 id='FeesCollection-header-title'>Fees Collection</h2>
          <div
            className='FeesCollection-header-addAmount'
            onClick={() => {
              const createFeesCollectionForm =
                document.getElementById("AddAmount-form");
              createFeesCollectionForm.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Add Amount
          </div>
        </div>
        <div className='FeesCollectionCard-container'>
          <div className='FeesCollection-row'>
            <span id='FeesCollectionCard-Name'>Name</span>
            <span id='FeesCollectionCard-amount'>Amount</span>
            <span id='FeesCollectionCard-date'>Date</span>
            <span id='FeesCollectionCard-time'>Time</span>
            <span id='FeesCollectionCard-mode'>Mode</span>
            <span></span>
          </div>
          <div className='FeesCollection-main'>
            {!loading ? (
              collections.length > 0 ? (
                <>
                  {collections.map((collection, index) => (
                    <Link
                      to={`/member-information/${collection.memberId}`}
                      style={{ textDecoration: "none" }}
                    >
                      <FeesCollectionCard
                        key={index}
                        name={collection.memberName}
                        amount={collection.amount}
                        date={new Date(collection.date).toLocaleDateString()}
                        mode={collection.mode}
                        time={collection.updatedAt}
                        memberId={collection.memberId}
                        collectionId={collection._id}
                      />
                    </Link>
                  ))}
                </>
              ) : (
                <p id='FeesCollection-noCollection'>
                  No fee collections found.
                </p>
              )
            ) : (
              <>
                <Loading />
              </>
            )}
          </div>
        </div>
        <h2 className='FeesCollection-graphHeader'>Collection Statistics</h2>
        <div className='FeesCollection-graphs'>
          <div className='FeesCollection-monthlyCollections'>
            <MonthlyDistributionChart data={monthlyCollectionData} />
          </div>
          <div className='FeesCollection-upiAndCashDistribution'>
            <PaymentDistributionChart data={paymentMethodData} />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            height: "1px",
            backgroundColor: "gray",
            margin: "40px 0px",
          }}
        />
        <div className='AddAmount-form-header'>Add Amount</div>
        <form
          className='FeesCollection-form'
          id='AddAmount-form'
          onSubmit={handleFormSubmit}
        >
          <div className='AddAmount-input-group-name'>
            <label htmlFor='AddAmount-name' id='AddAmount-nameSpan'>
              Member Name
            </label>
            <input
              type='text'
              id='AddAmount-name'
              name='memberName'
              value={formData.memberName}
              onChange={handleInputChange}
              placeholder="Enter member's name"
              required
            />
          </div>
          <div className='AddAmount-input-group-id'>
            <label htmlFor='AddAmount-id' id='AddAmount-idSpan'>
              Member ID
            </label>
            <input
              type='text'
              id='AddAmount-id'
              name='id'
              value={formData.id}
              onChange={handleInputChange}
              placeholder="Enter member's ID"
              required
            />
          </div>
          <div className='AddAmount-input-group-date'>
            <label htmlFor='AddAmount-date' id='AddAmount-dateSpan'>
              Date
            </label>
            <input
              type='date'
              id='AddAmount-date'
              name='date'
              value={formData.date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className='AddAmount-input-group-amount'>
            <label htmlFor='AddAmount-amount' id='AddAmount-amountSpan'>
              Amount
            </label>
            <input
              type='number'
              id='AddAmount-amount'
              name='amount'
              value={formData.amount === 0 ? "" : formData.amount}
              onChange={handleInputChange}
              placeholder='Enter a price'
              required
            />
          </div>
          <div className='AddAmount-input-group-mode'>
            <label htmlFor='AddAmount-mode' id='AddAmount-modeSpan'>
              Payment Mode
            </label>
            <select
              id='AddAmount-mode'
              name='mode'
              value={formData.mode}
              onChange={handleInputChange}
              required
            >
              <option value=''>Select Payment Mode</option>
              <option value='CASH'>Cash</option>
              <option value='UPI'>UPI</option>
              <option value='CARD'>Card</option>
            </select>
          </div>
          <button type='submit' id='AddAmount-submitButton'>
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default FeesCollection;

import React from "react";
import homeBackground from "../assets/homeBackground.png";
import { Link } from "react-router-dom";

const HomeText = () => {
  return (
    <div
      className="HomeText"
      style={{
        backgroundImage: `url(${homeBackground})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
      }}
    >
      <div className="HT-left-half">
        <div>
          <h1 id="HT-mainText">
            We simplify gym management <br /> so you can focus on <br /> what
            you love--fitness.
          </h1>
          <div id="HT-secondText">
            We aim to empower gym owners to focus their time and energy on what
            they love most
            <br /> -delivering exceptional fitness experience to their members
          </div>
          <br />
          <br />
          <button id="Get-Started">
            <Link to="/login">Get Started</Link>
          </button>
          <button id="Blog">
            <Link to="/blogs">Blogs</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeText;

import React from "react";
import axios from "axios";
import DustbinIcon from "../assets/DustbinIcon.png";

const FeesCollectionCard = ({
  amount,
  date,
  time,
  mode,
  memberId,
  collectionId,
  name,
}) => {
  if (!amount || !date || !time || !mode || !memberId || !collectionId) {
    return null;
  }

  const handleDelete = async () => {
    const offerIdentifier = { amount, date, time, mode };
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/gym/delete-member-collection/${memberId}/${collectionId}`,
        {
          data: offerIdentifier,
        }
      );
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  const localTimeString = new Date(time).toLocaleTimeString();

  return (
    <div className="FeesCollection-card">
      <div className="FeesCollectionCard-main">
        <span id="FeesCollectionCard-main-name">{name}</span>
        <span id="FeesCollectionCard-main-amount">{amount}</span>
        <span id="FeesCollectionCard-main-date">{date}</span>
        <span id="FeesCollectionCard-main-time">{localTimeString}</span>
        <span id="FeesCollectionCard-main-mode">{mode}</span>
        <img src={DustbinIcon} alt="Delete" onClick={handleDelete} id="FeesCollectionCard-main-delete" />
      </div>
    </div>
  );
};

export default FeesCollectionCard;

import React, { useState, useEffect, useCallback } from "react";
import NoticeCard from "../components/NoticeCard";
import axios from "axios";
import Dashboardpannel from "../components/Dashboardpannel";
import "../styles/NoticeInformation.css";
import Dashboard from "./Dashboard";
import { toast } from "react-toastify";
//optimisations
const NoticeInformation = () => {
  axios.defaults.withCredentials = true;

  const [formData, setFormData] = useState({
    issueDate: "",
    effectiveDate: "",
    details: "",
  });
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-gym-notice`
      );
      setNotices(response.data.notices.reverse() || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching notices:", error);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    setFormLoading(true);
    const { issueDate, effectiveDate, details } = formData;

    if (!issueDate || !effectiveDate || !details) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/gym/create-gym-notice`,
        formData
      );

      console.log(response.data.message);

      fetchNotices();
      setFormLoading(false);
      setFormData({
        issueDate: "",
        effectiveDate: "",
        details: "",
      });
      toast.success("Notice Added", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
      toast.error("Notice not added. Try Again.", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
      setFormLoading(false);
    }
  };

  const deleteNotice = useCallback(
    (deletedNoticeIdentifier) => {
      const updatedNotices = notices.filter(
        (notice) =>
          !(
            notice.issueDate === deletedNoticeIdentifier.issueDate &&
            notice.effectiveDate === deletedNoticeIdentifier.effectiveDate &&
            notice.details === deletedNoticeIdentifier.details
          )
      );
      setNotices(updatedNotices);
    },
    [notices]
  );

  if (loading) {
    return <>Loading</>; //WIP Loading Animation
  }

  return (
    <>
      <Dashboard />
      <div className='NoticeInformation-container'>
        <div className='NoticeInformation-header'>
          <h2 id='NoticeInformation-header-title'>Notice Information</h2>
          <div
            className='NoticeInformation-header-createNotice'
            onClick={() => {
              const createNoticeForm =
                document.getElementById("CreateNotice-form");
              createNoticeForm.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Create Notice
          </div>
        </div>
        <div className='NoticeCard-container'>
          <div className='NoticeInformation-row'>
            <span id='NoticeInformation-row-id'>Issue Date</span>
            <span id='NoticeInformation-row-ed'>Effective Date</span>
            <span id='NoticeInformation-row-details'>Details</span>
            <span></span>
          </div>
          <div className='NoticeInformation-main'>
            {notices.length > 0 ? (
              <>
                {notices.map((notice, index) => (
                  <NoticeCard
                    key={index}
                    issueDate={new Date(notice.issueDate).toLocaleDateString()}
                    effectiveDate={new Date(
                      notice.effectiveDate
                    ).toLocaleDateString()}
                    details={notice.details}
                    onDelete={deleteNotice}
                    noticeId={notice._id}
                  />
                ))}
              </>
            ) : (
              <p id='No-notice'>No notices found.</p>
            )}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            height: "1px",
            backgroundColor: "gray",
            margin: "40px 0px",
          }}
        />
        <div className='NoticeInformation-form-header'>Create Notice</div>
        <form
          id='CreateNotice-form'
          className='NoticeInformation-form'
          onSubmit={handleFormSubmit}
        >
          <div className='CreateNotice-input-group-issue-date'>
            <label
              htmlFor='CreateNotice-issue-date'
              id='CreateNotice-issueDateSpan'
            >
              Issue Date
            </label>
            <input
              type='date'
              id='CreateNotice-issue-date'
              value={formData.issueDate}
              onChange={handleInputChange}
              name='issueDate'
              required
            />
          </div>
          <div className='CreateNotice-input-group-effective-date'>
            <label
              htmlFor='CreateNotice-effective-date'
              id='CreateNotice-effectiveDateSpan'
            >
              Effective Date
            </label>
            <input
              type='date'
              id='CreateNotice-effective-date'
              value={formData.effectiveDate}
              onChange={handleInputChange}
              name='effectiveDate'
              required
            />
          </div>
          <div className='CreateNotice-input-group-details'>
            <label htmlFor='CreateNotice-details' id='CreateNotice-detailsSpan'>
              Details
            </label>
            <textarea
              rows='7'
              cols='42'
              id='CreateNotice-details'
              placeholder='Enter your text here'
              value={formData.details}
              onChange={handleInputChange}
              name='details'
              required
            />
          </div>
          <div>
            {formLoading ? (
              <>Loading...</> //WIP Loading Animation
            ) : (
              <button type='submit' id='CreateNotice-submitButton'>
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default NoticeInformation;

import { React, useState, useEffect } from "react";
import "../styles/ForgotPassword.css";
import { Link, useNavigate } from "react-router-dom";
import mainLogo from "../assets/mainLogo.png";
import tagline1 from "../assets/tagline1.png";
import tagline2 from "../assets/tagline2.png";
import tagline3 from "../assets/tagline3.png";
import tagline4 from "../assets/tagline4.png";
import tagline5 from "../assets/tagline5.png";
import tagline6 from "../assets/tagline6.png";
import tagline7 from "../assets/tagline7.png";
import tagline8 from "../assets/tagline8.png";
import axios from "axios";
//refactoring
const slideshowImages = [
  tagline1,
  tagline2,
  tagline3,
  tagline4,
  tagline5,
  tagline6,
  tagline7,
  tagline8,
];

const ResetPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    token: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/auth/reset-password/${formData.token}`,
        formData
      );
      navigate("/login");
    } catch (err) {
      console.log(err.message);
          navigate(`/error/${err.message}`)
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const changeImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % slideshowImages.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(changeImage, 2000); // changing image every 2 seconds
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className='fp-container'>
      <div className='fp-left-half'>
        <div className='logo'>
          <a href='/'>
            <img src={mainLogo} alt='Logit' id='mainLogo' />
          </a>
        </div>
        <h1 id='fp-h1'>
          Welcome to,
          <br /> Logit <span id='for'>for</span> Everyone
        </h1>
        <h2 id='Where-we'>Where we...</h2>
        <div className='slideshow'>
          <img
            src={slideshowImages[currentImageIndex]}
            alt='Slideshow'
            className='slideshow-image'
          />
        </div>
      </div>
      <div className='fp-right-half'>
        <div id='fp-p'>
          <h2>
            Reset Password <br />
            <span id='fp-span'>
              relax we are here to recovering your password
            </span>
          </h2>
        </div>
        <form onSubmit={handleSubmit} className='fp-form'>
          <div className='fp-form-group'>
            <label htmlFor='fp-emailInput' id='Email' className='fp-label'>
              token
            </label>
            <input
              type='text'
              name='token'
              id='fp-emailInput'
              placeholder='Please Enter Your Token provided on mail you gave '
              value={formData.token}
              onChange={handleInputChange}
              required
            />
            <label htmlFor='fp-emailInput' id='Email' className='fp-label'>
              New Password
            </label>
            <input
              type='text'
              name='password'
              id='fp-emailInput'
              placeholder='Please Enter Your Email'
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type='submit' id='fp-button'>
            Recover my password
          </button>
        </form>
        <div className='login-link'>
          Didn't forget your password? <Link to='/login'>Login </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React from "react";
import "../styles/MemberList.css";

const MemberCard = ({ imgSrc, name, id, contact, email }) => {
  return (
    <div className="Member-card">
      <div className="MemberCard-main">
        <div className="MemberCard-img">
          <img src={imgSrc} alt={name} className="MemberCard-image" />
        </div>
        <span id="MemberCard-name"> {name}</span>
        <span id="MemberCard-id"> {id}</span>
        <span id="MemberCard-contact"> {contact}</span>
        <span id="MemberCard-email">{email}</span>
      </div>
    </div>
  );
};

export default MemberCard;

import React from "react";
import Home3 from "../Assets/Home3.jpg";
import twitter from "../Assets/twitter-icon.svg";
import instagram from "../Assets/instagram-icon.svg";
import facebook from "../Assets/facebook-icon.svg";
import linkedin from "../Assets/linkedin-icon.svg";

const MemberConnect = () => {
  const handleSubmit = () => {
    alert("Form submitted. Thank you!");
  };
  return (
    <>
      <div className="member-Connect-top">
        <span id="member-Connect-topSpan">Any Query? Let's Connect</span>
      </div>
      <div className="member-Connect-middle">
        <form
          action="mailto:logitsocial@gmail.com"
          id="member-Connect-form"
          method="post"
          encType="text/plain"
          onSubmit={handleSubmit}
        >
          <span id="member-Connect-name">Name</span>
          <input
            type="text"
            name="name"
            id="member-Connect-nameInput"
            placeholder="Please enter your name"
            required
          />
          <br />
          <span id="member-Connect-email">Email</span>
          <input
            type="email"
            name="email"
            id="member-Connect-emailInput"
            placeholder="Please enter your email"
            required
          />
          <br />
          <span id="member-Connect-query">Query</span>
          <textarea
            name="query"
            id="member-Connect-queryInput"
            cols="25"
            rows="8"
            placeholder="Please write your query"
            required
          ></textarea>
          <br />
          <br />
          <button type="submit" id="member-Connect-button">
            Submit
          </button>
        </form>
        <img src={Home3} alt="Let'Connect" id="member-Connect-image" />
      </div>
      <div className="member-Connect-bottom">
        <span id="member-Connect-bottomSpan">Join our community</span>
        <div className="member-Connect-bottomIcons">
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/logit_community/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="twitter" />
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img src={linkedin} alt="linkedin" />
          </a>
        </div>
      </div>
    </>
  );
};

export default MemberConnect;

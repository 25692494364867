import React from "react";
import CEO from "../../assets/CEO.png";
import CTO from "../../assets/CTO.png";
import MemberProfileCard from "./MemberProfileCard";

const details = [
  {
    profilePic: CEO,
    name: "Pratham Aggarwal",
    description:
      "We will make gym management as easy as lifting a 5 KG dumbell after 1 month of training.",
  },
  {
    profilePic: CTO,
    name: "Suman Saurav",
    description:
      "We will make gym management so light that even a 2.5 KG dumbell will feel heavier.",
  },
];

const MemberAboutUs = () => {
  return (
    <>
      <div>
        <h1 id="member-AboutUs-h1">Who are we</h1>
        <div className="member-AboutUs-Cards">
          {details.map((detail, index) => (
            <MemberProfileCard
              key={index}
              ePic={detail.profilePic}
              name={detail.name}
              description={detail.description}
            />
          ))}
        </div>
        <div className="member-AboutUs-bottom">
          <span className="member-AboutUs-visionAim">Our Vision</span>
          <p className="member-AboutUs-para">
            We want to make running gyms easy for owners.
            <br />
            Our Vision is to provide gym owners with tools
            <br />
            that simplify everything, so they can pull all their <br />
            energy into training members and making their <br />
            gyms fantastic. We dream of a world where gym
            <br />
            owners can focus on what they love, knowing
            <br />
            that the rest is effortlessly taken care of.
          </p>
        </div>
        <div className="member-AboutUs-bottom">
          <span className="member-AboutUs-visionAim">Our Aim</span>
          <p className="member-AboutUs-para1">
            Our aim is to create user-friendly gym
            <br />
            management software that takes the hassle out
            <br />
            of running a gym. We aim to have more and more
            <br />
            gym owners use our software to make their lives
            <br />
            easier. This way, they can dedicate themselves to
            <br />
            member training and improving their gyms
            <br />
            without any worries.
          </p>
        </div>
      </div>
    </>
  );
};

export default MemberAboutUs;

import React from "react";

const PricingCard = ({
  logo,
  title,
  price,
  member,
  description1,
  description2,
  description3,
}) => {
  return (
    <div className="pricingCard-main">
      <div className="pricingCard-top">
        <img src={logo} alt="logo" id="pricingCard-logo" />
        <br />
        <span id="pricingCard-title">{title}</span>
        <br />
        <span id="pricingCard-price">{price}</span>
        <br />
        <span id="pricingCard-member">{member}</span>
      </div>
      <div className="pricingCard-bottom">
        <p className="pricingCard-description">{description1}</p>
        <p className="pricingCard-description">{description2}</p>
        <p className="pricingCard-description">{description3}</p>
      </div>
    </div>
  );
};

export default PricingCard;

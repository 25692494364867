import React from "react";

const IndividualMemberAttendanceCard = ({ name, id, time, day, date }) => {
  return (
    <div className="imac-main">
      <span id="imac-main-name">{name}</span>
      <span id="imac-main-id">{id}</span>
      <span id="imac-main-time">{time}</span>
      <span id="imac-main-day">{day}</span>
      <span id="imac-main-date">{date}</span>
    </div>
  );
};

export default IndividualMemberAttendanceCard;

import React from "react";
import { Link } from "react-router-dom";
import Home2 from "../Assets/Home2.jpg";

const MemberHomeText = () => {
  return (
    <div className="memberHomeText">
      <div className="memberHT-left-half">
        <h1 id="memberHT-mainText">
          Making every step of your <br /> fitness journey <br /> feel like a
          victory lap.
        </h1>
        <div id="memberHT-secondText">
          We aim to make your fitness journey appreciable with full of goals
          <br /> being crushed like a pro and a little bit of statistics
        </div>
        <br />
        <br />
        <p>
          <span id="memberGet-Started">
            <Link to="/member/login">Get Started</Link>
          </span>
        </p>
      </div>
      <div className="memberHT-right-half">
        <img src={Home2} alt="Img" id="memberHT-Home1" />
      </div>
    </div>
  );
};

export default MemberHomeText;

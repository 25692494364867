import React from "react";
import { Link } from "react-router-dom";

const MemberDashboardCard = ({
  DashboardLogo,
  DashboardInformation,
  link,
  memberId,
}) => {
  return (
    <div className="member-DashboardCard-main">
      <div className="member-DashboardCard-logo">
        <img
          src={DashboardLogo}
          alt="DashboardLogo"
          id="member-Dashboard-logo-id"
        />
      </div>
      <Link to={`${link}/${memberId}`} style={{ textDecoration: "none" }}>
        <div className="member-DashboardCard-Information">
          {DashboardInformation}
        </div>
      </Link>
    </div>
  );
};

export default MemberDashboardCard;

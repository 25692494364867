import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import MemberCard from "../components/MemberCard";
import imageUploadIcon from "../assets/imageUploadIcon.png";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import Dashboardpannel from "../components/Dashboardpannel";
import Dashboard from "./Dashboard";
import BarCharts from "../components/barCharts";
import MembershipDistribution from "../components/memberSHipDistribution";
import AgeDistribution from "../components/AgeDistribution";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const MemberList = () => {
  axios.defaults.withCredentials = true;

  const [state, setState] = useState({
    memberListData: [],
    memberListCount: "",
    searchTerm: "",
    image: null,
    name: "",
    email: "",
    contact: "",
    address: "",
    emergencyContact: "",
    id: "",
    gender: "",
    startDate: "",
    endDate: "",
    membership: "",
    preloadedImage: null,
    dob: "",
    genderChartData: {},
    membershipChartData: {},
    ageChartData: {},
    loading: true,
    error: null
  });

  const fetchChartData = useCallback(async () => {
    try {
      const genderRes = await axios.get(`${process.env.REACT_APP_BASE_URL}/gym/gender-distribution`);
      const ageRes = await axios.get(`${process.env.REACT_APP_BASE_URL}/gym/age-distribution`);
      const membershipRes = await axios.get(`${process.env.REACT_APP_BASE_URL}/gym/membership-distribution`);

      setState(prevState => ({
        ...prevState,
        genderChartData: {
          females: genderRes.data.females,
          males: genderRes.data.males,
          others: genderRes.data.others,
        },
        ageChartData: {
          "<21": ageRes.data.lessThan21,
          "21-25": ageRes.data.between21To25,
          "26-30": ageRes.data.between25To30,
          "31-35": ageRes.data.between30To35,
          "36-40": ageRes.data.between35To40,
          ">40": ageRes.data.moreThan40,
        },
        membershipChartData: {
          halfYearly: parseInt(membershipRes.data.halfYearly),
          monthly: parseInt(membershipRes.data.monthly),
          quarterly: parseInt(membershipRes.data.quarterly),
          yearly: parseInt(membershipRes.data.yearly),
        },
        loading: false,
        error: null
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: error.message
      }));
    }
  }, []);

  useEffect(() => {
    fetchChartData();
  }, [fetchChartData]);

  const handleImageClick = () => {
    document.getElementById("Gym-image").click();
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`MemberImage-${selectedImage.name}`);

      fileRef.put(selectedImage).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          setState(prevState => ({
            ...prevState,
            image: downloadURL
          }));
        });
      });
    } else {
      setState(prevState => ({
        ...prevState,
        image: "Gym Image not uploaded"
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { image, name, address, email, emergencyContact, contact, id, gender, startDate, endDate, membership, dob } = state;

    if (!image || !name || !address || !email || !emergencyContact || !contact || !id || !gender || !startDate || !endDate || !membership) {
      alert("Please fill in all required fields.");
      return;
    }

    const formData = {
      displayImage: image,
      name,
      dob,
      contact,
      email,
      gender,
      address,
      emergencyContact,
      id,
      startDate,
      endDate,
      membershipPlan: membership,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/gym/add-member`,
        formData
      );
      if (res.status === 200) {
        toast.success("Member Added ", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: false,
        });
        fetchMemberListData(); // Refresh the member list after adding a new member
      }
    } catch (error) {
      console.error("Error submitting gym data:", error);
    }
  };

  const fetchMemberListData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/gym/show-members`);
      setState(prevState => ({
        ...prevState,
        memberListData: response.data.members.reverse(),
        memberListCount: response.data.memLen,
        loading: false,
        error: null
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error: error.message
      }));
    }
  };

  useEffect(() => {
    fetchMemberListData();
  }, []);

  const filteredMemberList = useMemo(() => {
    return state.memberListData.filter((member) =>
      Object.values(member).some((field) =>
        field.toString().toLowerCase().includes(state.searchTerm.toLowerCase())
      )
    );
  }, [state.memberListData, state.searchTerm]);

  if (state.loading) {
    return <div>Loading...</div>;
  }

  if (state.error) {
    return <div>Error: {state.error}</div>;
  }

  return (
    <>
      <Dashboard />
      <div className="MemberList-container">
        <div className="MemberList-header">
          <h2 id="MemberList-header-title">Members-</h2>
          <h3 id="MemberList-header-count">({state.memberListCount})</h3>
          <div
            className="MemberList-addMember"
            onClick={() => {
              const addMemberForm = document.getElementById("addMemberForm");
              addMemberForm.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Add Member
          </div>
        </div>
        <div className="MemberCard-container">
          <div className="MemberList-searchBar">
            <input
              type="text"
              placeholder="Search Here"
              id="MemberList-searchBar-input"
              className="Search-input"
              value={state.searchTerm}
              onChange={(e) => setState(prevState => ({
                ...prevState,
                searchTerm: e.target.value
              }))}
            />
          </div>
          <div className="MemberList-row">
            <span> </span>
            <span>Name</span>
            <span id="MemberList-row-id">ID</span>
            <span id="MemberList-row-contact">Contact</span>
            <span id="MemberList-row-email">Email</span>
          </div>
          <div className="MemberList-main">
            {filteredMemberList.map((member, index) => (
              <Link
                to={`/member-information/${member._id}`}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <MemberCard
                  imgSrc={member.displayImage.toString()}
                  name={member.name}
                  id={member.id}
                  contact={member.contact}
                  email={member.email}
                />
              </Link>
            ))}
          </div>
        </div>
        <div className="MemberList-statistics">
          <h2 id="MemberList-statistics-header">Members Statistics</h2>
          <div className="MemberList-graphs">
            <div className="graph-member-gender">
              <BarCharts data={state.genderChartData} />
            </div>
            <div className="graph-member-membership" id="graph-member-md">
              <MembershipDistribution charData={state.membershipChartData} />
            </div>
            <div className="graph-member-membership" id="graph-member-ad">
              <AgeDistribution data={state.ageChartData} />
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            height: "1px",
            backgroundColor: "gray",
            margin: "40px 0px",
          }}
        />
        <div className="MemberList-form-header">Add Member</div>
        <form
          id="addMemberForm"
          className="MemberSetup-form"
          onSubmit={handleFormSubmit}
        >
          <div className="MemberSetup-img">
            <div className="MemberSetup-imgdiv" onClick={handleImageClick}>
              {state.image ? (
                <img
                  src={state.image}
                  alt="Gym Logo"
                  className="GymSetup-img-preview"
                />
              ) : state.preloadedImage ? (
                <img
                  src={state.preloadedImage}
                  alt="Preloaded Gym Logo"
                  className="MemberSetup-img-preview"
                />
              ) : (
                <img
                  src={imageUploadIcon}
                  alt="Upload Icon"
                  className="GymSetup-img-upload-icon"
                />
              )}
            </div>
            <input
              type="file"
              accept="image/*"
              id="Gym-image"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>
          <div className="MemberSetup-input-group-name">
            <label htmlFor="MemberSetup-name" id="MemberSetup-nameSpan">
              Name
            </label>
            <input
              type="text"
              id="MemberSetup-name"
              placeholder="Enter a name"
              value={state.name}
              onChange={(e) => setState(prevState => ({
                ...prevState,
                name: e.target.value
              }))}
              required
            />
          </div>
          <div className="MemberSetup-input-group-contact">
            <label htmlFor="MemberSetup-contact" id="MemberSetup-contactSpan">
              Contact
            </label>
            <PhoneInput
              id="MemberSetup-contact"
              placeholder="Enter a contact number"
              value={state.contact}
              defaultCountry="IN"
              onChange={(value) => setState(prevState => ({
                ...prevState,
                contact: value
              }))}
              maxLength="11"
              minLength="11"
              required
            />
          </div>
          <div className="MemberSetup-input-group-email">
            <label htmlFor="MemberSetup-email" id="MemberSetup-emailSpan">
              Email
            </label>
            <input
              type="email"
              id="MemberSetup-email"
              placeholder="Enter a email"
              value={state.email}
              onChange={(e) => setState(prevState => ({
                ...prevState,
                email: e.target.value
              }))}
              required
            />
          </div>
          <div className="MemberSetup-input-group-birth-date">
            <label
              htmlFor="MemberSetup-birth-date"
              id="MemberSetup-birthDateSpan"
            >
              Date Of Birth
            </label>
            <input
              type="date"
              id="MemberSetup-birth-date"
              value={state.dob}
              onChange={(e) => setState(prevState => ({
                ...prevState,
                dob: e.target.value
              }))}
              required
            />
          </div>
          <div className="MemberSetup-input-group-address">
            <label htmlFor="MemberSetup-address" id="MemberSetup-addressSpan">
              Address
            </label>
            <input
              type="text"
              id="MemberSetup-address"
              placeholder="Enter an address"
              value={state.address}
              onChange={(e) => setState(prevState => ({
                ...prevState,
                address: e.target.value
              }))}
              required
            />
          </div>
          <div className="MemberSetup-input-group-emergency_contact">
            <label
              htmlFor="MemberSetup-contact"
              id="MemberSetup-emergency_contactSpan"
            >
              Emergency Contact
            </label>
            <PhoneInput
              id="MemberSetup-emergency_contact"
              placeholder="Enter a contact number"
              value={state.emergencyContact}
              defaultCountry="IN"
              onChange={(value) => setState(prevState => ({
                ...prevState,
                emergencyContact: value
              }))}
              maxLength="11"
              minLength="11"
              required
            />
          </div>
          <div className="MemberSetup-input-group-id">
            <label htmlFor="MemberSetup-id" id="MemberSetup-idSpan">
              ID
            </label>
            <input
              type="text"
              id="MemberSetup-id"
              placeholder="Enter an ID"
              value={state.id}
              onChange={(e) => setState(prevState => ({
                ...prevState,
                id: e.target.value
              }))}
              required
            />
          </div>
          <div className="MemberSetup-input-group-gender">
            <label htmlFor="MemberSetup-gender" id="MemberSetup-genderSpan">
              Gender
            </label>
            <select
              value={state.gender}
              id="MemberSetup-gender"
              onChange={(e) => setState(prevState => ({
                ...prevState,
                gender: e.target.value
              }))}
              required
            >
              <option value="">Select Gender</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
              <option value="OTHERS">Others</option>
            </select>
          </div>
          <div className="MemberSetup-input-group-start-date">
            <label
              htmlFor="MemberSetup-start-date"
              id="MemberSetup-startDateSpan"
            >
              Start Date
            </label>
            <input
              type="date"
              id="MemberSetup-start-date"
              value={state.startDate}
              onChange={(e) => setState(prevState => ({
                ...prevState,
                startDate: e.target.value
              }))}
              required
            />
          </div>
          <div className="MemberSetup-input-group-end-date">
            <label htmlFor="MemberSetup-end-date" id="MemberSetup-endDateSpan">
              End Date
            </label>
            <input
              type="date"
              id="MemberSetup-end-date"
              value={state.endDate}
              onChange={(e) => setState(prevState => ({
                ...prevState,
                endDate: e.target.value
              }))}
              required
            />
          </div>
          <div className="MemberSetup-input-group-membership">
            <label
              htmlFor="MemberSetup-membership"
              id="MemberSetup-membershipSpan"
            >
              Membership Plan
            </label>
            <select
              value={state.membership}
              id="MemberSetup-membership"
              onChange={(e) => setState(prevState => ({
                ...prevState,
                membership: e.target.value
              }))}
              required
            >
              <option value="">Select a Plan</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="half_Yearly">Half Yearly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>
          <div>
            <button type="submit" id="MemberSetup-submitButton">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default MemberList;

import { React, useState, useEffect } from "react";
import OfferCard from "../components/OfferCard";
import axios from "axios";
//not for now will be in production soon
const OfferInformation = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [offerDuration, setOfferDuration] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [offers, setOffers] = useState([]);

  const fetchOffers = async () => {
    try {
      const response = await axios.get("http://localhost:5000/api/getOffers"); // Change URL accordingly
      setOffers(response.data.offers); // Assuming the response contains notices array
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!startDate || !endDate || !offerDuration || !offerPrice) {
      alert("Please fill in all required fields.");
      return;
    }

    const formData = {
      startDate,
      endDate,
      offerDuration,
      offerPrice,
    };

    try {
      // Send form data to the backend
      const response = await axios.post(
        "http://localhost:5000/api/submitOffer",
        formData
      ); // Change url accordingly

      // Handle the response, e.g., show a success message
      console.log(response.data.message);

      fetchOffers();

      setStartDate("");
      setEndDate("");
      setOfferDuration("");
      setOfferPrice("");
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error submitting offer data:", error);
    }
  };

  const deleteOffers = (deletedOfferIdentifier) => {
    const updatedOffers = offers.filter(
      (offer) =>
        !(
          offer.startDate === deletedOfferIdentifier.startDate &&
          offer.endDate === deletedOfferIdentifier.endDate &&
          offer.offerDuration === deletedOfferIdentifier.offerDuration &&
          offer.offerPrice === deletedOfferIdentifier.offerPrice
        )
    );
    setOffers(updatedOffers);
  };

  return (
    <div className="OfferInformation container">
      <div className="OfferInformation-header">
        <h2 id="OfferInformation-header-title"> Offer Information</h2>
        <div
          className="OfferInformation-header-createOffer"
          onClick={() => {
            const createOfferForm = document.getElementById("createOfferForm");
            createOfferForm.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Create Offer
        </div>
      </div>
      <div className="OfferCard-container">
        <div className="OfferInformation-row">
          <span id="OfferCard-startDate">Start Date</span>
          <span id="OfferCard-endDate">End Date</span>
          <span id="OfferCard-offerDuration">Offer Duration</span>
          <span id="OfferCard-offerPrice">Offer Price</span>
          <span id="OfferCard-delete"></span>
        </div>
        <div className="OfferInformation-main">
          {offers.map((offer, index) => (
            <OfferCard
              key={index}
              startDate={offer.startDate}
              endDate={offer.endDate}
              offerDuration={offer.offerDuration}
              onDelete={deleteOffers}
            />
          ))}
          <OfferCard
            startDate="25/11/2023"
            endDate="01/12/2023"
            offerDuration="3 months"
            offerPrice="1600"
          />
          <OfferCard
            startDate="25/11/2023"
            endDate="01/12/2023"
            offerDuration="3 months"
            offerPrice="1600"
          />
          <OfferCard
            startDate="25/11/2023"
            endDate="01/12/2023"
            offerDuration="1 year"
            offerPrice="1600"
          />
          <OfferCard
            startDate="25/11/2023"
            endDate="01/12/2023"
            offerDuration="6 months"
            offerPrice="1600"
          />
          <OfferCard
            startDate="25/11/2023"
            endDate="01/12/2023"
            offerDuration="3 months"
            offerPrice="1600"
          />
          <OfferCard
            startDate="25/11/2023"
            endDate="01/12/2023"
            offerDuration="1 year"
            offerPrice="1600"
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "1px",
          backgroundColor: "gray",
          margin: "40px 0px",
        }}
      />
      <div className="CreateOffer-form-header">Create Offer</div>
      <form
        id="createOfferForm"
        className="OfferInformation-form"
        onSubmit={handleFormSubmit}
      >
        <div className="CreateOffer-input-group-offerDuration">
          <label
            htmlFor="CreateOffer-offerDuration"
            id="CreateOffer-offerDurationSpan"
          >
            Offer Duration
          </label>
          <select
            value={offerDuration}
            id="CreateOffer-offerDuration"
            onChange={(e) => setOfferDuration(e.target.value)}
            required
          >
            <option value="">Select Duration</option>
            <option value="1months">1 month</option>
            <option value="3months">3 months</option>
            <option value="6months">6 months</option>
            <option value="1year">1 year</option>
          </select>
        </div>
        <div className="CreateOffer-input-group-offerPrice">
          <label
            htmlFor="CreateOffer-offerPrice"
            id="CreateOffer-offerPriceSpan"
          >
            Offer Price
          </label>
          <input
            type="number"
            id="CreateOffer-offerPrice"
            value={offerPrice === 0 ? "" : offerPrice}
            onChange={(e) => setOfferPrice(Math.max(0, e.target.value))}
            placeholder="Enter a price"
            required
          />
        </div>
        <div className="CreateOffer-input-group-start-date">
          <label
            htmlFor="CreateOffer-start-date"
            id="CreateOffer-startDateSpan"
          >
            Start Date
          </label>
          <input
            type="date"
            id="CreateOffer-start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </div>
        <div className="CreateOffer-input-group-end-date">
          <label htmlFor="CreateOffer-end-date" id="CreateOffer-endDateSpan">
            End Date
          </label>
          <input
            type="date"
            id="CreateOffer-end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </div>
        <div>
          <button
            type="submit"
            id="CreateOffer-submitButton"
            onClick={handleFormSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default OfferInformation;

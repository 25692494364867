import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Html5QrcodePlugin from "../MemberComponents/html5-qrCode";

const QrScanner = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();
  const [, setScannerData] = useState();
  const [, setMember] = useState();
  const [error, setError] = useState("");

  const validateGym = async (data) => {
    const memberResponse = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/gym/show-member-by-id/${memberId}`
    );
    const member = memberResponse.data.member;
    setMember(member);
    console.log("data",memberResponse.data.member)

    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/gym/show-gym-by-id/${member.gymId}`
    );
    if (res.data.gym.qrCode === data) {
    console.log("gym",data)
      markAttendance();
    } else {
      setError("not a Valid Qr Code");
    }
  };
  const handleScan = (data) => {
    setScannerData(data);
    data = data.replace(/"/g , '')
    validateGym(data);
    if (error === "") {
      navigate(`/member/attendance/${memberId}`);
    }
  };
  const markAttendance = async () => {
    try {
      const memberResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-member-by-id/${memberId}`
      );
      const member = memberResponse.data.member;
      setMember(member);

      const attendanceDetails = {
        name: member.name,
        id: member.id,
        attended: true,
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/gym/member-attendance/${memberId}`,
        attendanceDetails
      );
    } catch (error) {
      console.error("Error marking attendance: ", error);
    }
  };
  return (
    <div>
      {error}
      <Html5QrcodePlugin
        fps={10}
        qrbox={250}
        disableFlip={false}
        qrCodeSuccessCallback={handleScan}
      />
    </div>
  );
};

export default QrScanner;

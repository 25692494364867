import React from "react";
import Home2 from "../assets/Home2.jpg";
import facebook from "../assets/facebook.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";
import instagram from "../assets/instagram.png";

const Connect = () => {

  const handleSubmit = () => {
    alert("Form submitted. Thank you!");
  };
  return (
    <>
      <div className="Connect-top">
        <span id="Connect-topSpan">Any Query? Let's Connect</span>
      </div>
      <div className="Connect-middle">
        <form action="mailto:sumansaurav166@gmail.com" id="Connect-form" method="post" encType="text/plain" onSubmit={handleSubmit}>
          <span id="Connect-name">Name</span>
          <input
            type="text"
            name="name"
            id="Connect-nameInput"
            placeholder="Please enter your name"
            required
          />
          <br />
          <span id="Connect-email">Email</span>
          <input
            type="email"
            name="email"
            id="Connect-emailInput"
            placeholder="Please enter your email"
            required
          />
          <br />
          <span id="Connect-query">Query</span>
          <textarea
            name="query"
            id="Connect-queryInput"
            cols="25"
            rows="8"
            placeholder="Please write your query"
            required
          ></textarea>
          <br />
          <br />
          <button type="submit" id="Connect-button">
            Submit
          </button>
        </form>
        <img src={Home2} alt="Let'Connect" id="Connect-image" />
      </div>
      <div className="Connect-bottom">
        <span id="Connect-bottomSpan">Join our community</span>
        <div className="Connect-bottomIcons">
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img src={facebook} id="Connect-facebook" alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/fit.logit/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} id="Connect-instagram" alt="instagram" />
          </a>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img src={twitter} id="Connect-twitter" alt="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/wearelogit/" target="_blank" rel="noopener noreferrer">
            <img src={linkedin}  id="Connect-linkedin" alt="linkedin" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Connect;

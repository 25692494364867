import React from 'react'
import "../styles/FAQ.css"



const FAQ = () => {
  return (
    
    <div>
        <h1 id="Faq-header">
            FAQ
        </h1>
        <iframe
          src="https://www.youtube.com/embed/bq2xpiRl8U4?si=d7_05bNiNTzainSx"
          frameborder="0"
          allow="autoplay; encrypted-media"
          id="Faq-iframe"
          allowfullscreen
          title="logit video"
        />
    </div>
  )
}

export default FAQ
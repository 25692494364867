import React from "react";
import { Link } from "react-router-dom";
import "../styles/ErrorPage.css"; // Import CSS file

const ErrorPage = () => {
  return (
    <div className="error-page-container">
      <h1 className="error-heading">Oops! Unable to find your account.</h1>
      <p className="error-message">
        Please make sure the gym owner has registered you in the gym and that
        you've registered with the same email ID.
      </p>
      <Link to="/member/signup" className="register-link">
        Register Now
      </Link>
    </div>
  );
};

export default ErrorPage;

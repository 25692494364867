import React from "react";

const Services_card = ({ logo, title, description }) => {
  return (
    <div className="serviceCard-main">
      <div className="serviceCard-top">
        <img src={logo} alt="pic" id="serviceCard-img" />
      </div>
      <div className="serviceCard-middle">{title}</div>
      <div className="serviceCard-bottom">
        <p id="serviceCard-description">{description}</p>
      </div>
    </div>
  );
};

export default Services_card;

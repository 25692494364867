import React, { useState } from "react";
import "../styles/Dashboard.css";
import { Navigate } from "react-router-dom";
import imageUploadIcon from "../assets/imageUploadIcon.png";
// import preloadedImage from "../assets/preloadedImage.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import mainLogo from "../assets/mainLogo.png";
import QRCode from "qrcode.react";
import { saveAs } from "file-saver";
import axios from "axios";
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const DashboardEdit = () => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    district: "",
    pincode: "",
    fees: {
      monthlyFees: 0,
      quarterlyFees: 0,
      halfYearlyFees: 0,
      yearlyFees: 0,
    },
    timing: {
      morningOpen: "",
      morningClose: "",
      eveningOpen: "",
      eveningClose: "",
    },
    gender: "",
    description: "",
    instagramId: "",
    displayImage: "",
    preloadedImage: null,
    selectedImages: [],
    previewImages: [""],
    ownerDetails: {
      name: "",
      contact: "",
      designation: "",
    },
    qrCode: "",
  });

  const [loading, setLoading] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  const handleImageClick = () => {
    document.getElementById("dashboard-image").click();
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`DashboardImage-${selectedImage.name}`);

      fileRef.put(selectedImage).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL);
          setFormData({ ...formData, displayImage: downloadURL });
        });
      });
    } else {
      setFormData({
        ...formData,
        displayImage: "Dashboard Image not uploaded",
      });
    }
  };

  const handleImageUploadClick = () => {
    document.getElementById("dashboard-images").click();
  };

  function generateRandomString(length) {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      randomString += chars[randomIndex];
    }

    return randomString;
  }

  const handleGenerateQRCode = () => {
    if (
      !formData.displayImage ||
      !formData.name ||
      !formData.address ||
      !formData.district ||
      !formData.pincode ||
      !formData.fees ||
      !formData.ownerDetails
    ) {
      toast.warning("Please fill in all required fields.");
      return;
    }
    const generatedQRCodeData = generateRandomString(20);

    setFormData({ ...formData, qrCode: generatedQRCodeData });
  };

  const handleDownloadQRCode = () => {
    try {
      setLoading(true);
      const canvas = document.getElementById("dashboard-qr-code-canvas");
      canvas.toBlob((blob) => {
        saveAs(blob, "dashboard_qr_code.png");
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/gym/update-gym`, formData)
        .then((res) => {
          setLoading(false);
          setRedirectToDashboard(true);
        });
      setLoading(false);
    } catch (error) {
      console.error("Error submitting dashboard data:", error);
      setLoading(false);
      toast.error("Error submitting dashboard data");
    }
  };

  if (redirectToDashboard) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <div className='DashboardEdit-main'>
      <div className='DashboardEdit-nav'>
        <h1 id='DashboardEdit-heading'>
          Edit your dashboard <br />{" "}
          <span id='DashboardEdit-heading-span'> and </span>
          <p id='DashboardEdit-heading-p'>Manage your dashboard with us</p>
        </h1>
      </div>
      <h2 id='DashboardEdit-title'>Step 1 : Dashboard Details</h2>
      <form className='DashboardEdit-form' onSubmit={handleFormSubmit}>
        <div className='DashboardEdit-img'>
          <div className='DashboardEdit-imgdiv' onClick={handleImageClick}>
            {formData.displayImage ? (
              <img
                src={formData.displayImage}
                alt='Dashboard Logo'
                className='DashboardEdit-img-preview'
              />
            ) : (
              // : preloadedImage ? (
              //   <img
              //     src={preloadedImage}
              //     alt='Preloaded Dashboard Logo'
              //     className='DashboardEdit-img-preview'
              //   />
              // )
              <img
                src={imageUploadIcon}
                alt='Upload Icon'
                className='DashboardEdit-img-upload-icon'
              />
            )}
          </div>
          <input
            type='file'
            accept='image/*'
            id='dashboard-image'
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
        <div className='DashboardEdit-input-group-name'>
          <label htmlFor='DashboardEdit-name' id='DashboardEdit-nameSpan'>
            Name
          </label>
          <input
            type='text'
            id='DashboardEdit-name'
            placeholder='Enter a name'
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
        </div>
        <div className='DashboardEdit-input-group-address'>
          <label htmlFor='DashboardEdit-address' id='DashboardEdit-addressSpan'>
            Address
          </label>
          <input
            type='text'
            id='DashboardEdit-address'
            placeholder='Enter an address'
            value={formData.address}
            onChange={(e) =>
              setFormData({ ...formData, address: e.target.value })
            }
            required
          />
        </div>
        <div className='DashboardEdit-input-group-district'>
          <label
            htmlFor='DashboardEdit-district'
            id='DashboardEdit-districtSpan'
          >
            District
          </label>
          <input
            type='text'
            id='DashboardEdit-district'
            placeholder='Enter a district'
            value={formData.district}
            onChange={(e) =>
              setFormData({ ...formData, district: e.target.value })
            }
            required
          />
        </div>
        <div className='DashboardEdit-input-group-pincode'>
          <label htmlFor='DashboardEdit-pincode' id='DashboardEdit-pincodeSpan'>
            Pincode
          </label>
          <input
            type='text'
            id='DashboardEdit-pincode'
            placeholder='Enter a pincode'
            value={formData.pincode}
            onChange={(e) =>
              setFormData({ ...formData, pincode: e.target.value })
            }
            required
          />
        </div>
        <div className='DashboardEdit-input-group-fees'>
          <label
            htmlFor='DashboardEdit-monthlyfees'
            id='DashboardEdit-feesSpan'
          >
            Fees
          </label>
          <input
            type='number'
            id='DashboardEdit-monthlyfees'
            placeholder='Enter monthly fees'
            value={
              formData.fees.monthlyFees === 0 ? "" : formData.fees.monthlyFees
            }
            onChange={(e) =>
              setFormData({
                ...formData,
                fees: { ...formData.fees, monthlyFees: e.target.value },
              })
            }
            required
            min='0'
          />
          <input
            type='number'
            id='DashboardEdit-quarterlyfees'
            placeholder='Enter quarterly fees'
            value={
              formData.fees.quarterlyFees === 0
                ? ""
                : formData.fees.quarterlyFees
            }
            onChange={(e) =>
              setFormData({
                ...formData,
                fees: { ...formData.fees, quarterlyFees: e.target.value },
              })
            }
            required
            min='0'
          />
          <input
            type='number'
            id='DashboardEdit-halfYearlyfees'
            placeholder='Enter half-yearly fees'
            value={
              formData.fees.halfYearlyFees === 0
                ? ""
                : formData.fees.halfYearlyFees
            }
            onChange={(e) =>
              setFormData({
                ...formData,
                fees: { ...formData.fees, halfYearlyFees: e.target.value },
              })
            }
            required
            min='0'
          />
          <input
            type='number'
            id='DashboardEdit-yearlyfees'
            placeholder='Enter yearly fees'
            value={
              formData.fees.yearlyFees === 0 ? "" : formData.fees.yearlyFees
            }
            onChange={(e) =>
              setFormData({
                ...formData,
                fees: { ...formData.fees, yearlyFees: e.target.value },
              })
            }
            required
            min='0'
          />
        </div>
        <div className='DashboardEdit-input-group-timing'>
          <label htmlFor='DashboardEdit-timing' id='DashboardEdit-timingSpan'>
            Timing
          </label>
          <div className='DashboardEdit-timing'>
            <input
              type='time'
              id='DashboardEdit-morningopen'
              value={formData.timing.morningOpen}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  timing: { ...formData.timing, morningOpen: e.target.value },
                })
              }
              required
            />
            <input
              type='time'
              id='DashboardEdit-morningclose'
              value={formData.timing.morningClose}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  timing: { ...formData.timing, morningClose: e.target.value },
                })
              }
              required
            />
            <input
              type='time'
              id='DashboardEdit-eveningopen'
              value={formData.timing.eveningOpen}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  timing: { ...formData.timing, eveningOpen: e.target.value },
                })
              }
              required
            />
            <input
              type='time'
              id='DashboardEdit-eveningclose'
              value={formData.timing.eveningClose}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  timing: { ...formData.timing, eveningClose: e.target.value },
                })
              }
              required
            />
          </div>
        </div>
        <div className='DashboardEdit-input-group-gender'>
          <label htmlFor='DashboardEdit-gender' id='DashboardEdit-genderSpan'>
            Gender
          </label>
          <select
            id='DashboardEdit-gender'
            value={formData.gender}
            onChange={(e) =>
              setFormData({ ...formData, gender: e.target.value })
            }
            required
          >
            <option value=''>Select gender</option>
            <option value='male'>Male</option>
            <option value='female'>Female</option>
            <option value='other'>Other</option>
          </select>
        </div>
        <div className='DashboardEdit-input-group-description'>
          <label
            htmlFor='DashboardEdit-description'
            id='DashboardEdit-descriptionSpan'
          >
            Description
          </label>
          <textarea
            id='DashboardEdit-description'
            placeholder='Enter a description'
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            required
          />
        </div>
        <div className='DashboardEdit-input-group-instagram'>
          <label
            htmlFor='DashboardEdit-instagramId'
            id='DashboardEdit-instagramSpan'
          >
            Instagram ID
          </label>
          <input
            type='text'
            id='DashboardEdit-instagramId'
            placeholder='Enter Instagram ID'
            value={formData.instagramId}
            onChange={(e) =>
              setFormData({ ...formData, instagramId: e.target.value })
            }
            required
          />
        </div>
        <div className='DashboardEdit-input-group-ownerDetails'>
          <label
            htmlFor='DashboardEdit-ownerDetails'
            id='DashboardEdit-ownerDetailsSpan'
          >
            Owner Details
          </label>
          <input
            type='text'
            id='DashboardEdit-ownerName'
            placeholder='Owner Name'
            value={formData.ownerDetails.name}
            onChange={(e) =>
              setFormData({
                ...formData,
                ownerDetails: {
                  ...formData.ownerDetails,
                  name: e.target.value,
                },
              })
            }
            required
          />
          <PhoneInput
            id='DashboardEdit-ownerContact'
            placeholder='Owner Contact'
            value={formData.ownerDetails.contact}
            onChange={(contact) =>
              setFormData({
                ...formData,
                ownerDetails: {
                  ...formData.ownerDetails,
                  contact: contact,
                },
              })
            }
            required
          />
          <input
            type='text'
            id='DashboardEdit-ownerDesignation'
            placeholder='Owner Designation'
            value={formData.ownerDetails.designation}
            onChange={(e) =>
              setFormData({
                ...formData,
                ownerDetails: {
                  ...formData.ownerDetails,
                  designation: e.target.value,
                },
              })
            }
            required
          />
        </div>
        <div className='DashboardEdit-qrCode'>
          <button type='button' onClick={handleGenerateQRCode}>
            Generate QR Code
          </button>
          {formData.qrCode && (
            <div className='DashboardEdit-qrCode-display'>
              <QRCode
                id='dashboard-qr-code-canvas'
                value={formData.qrCode}
                size={128}
              />
              <button type='button' onClick={handleDownloadQRCode}>
                Download QR Code
              </button>
            </div>
          )}
        </div>
        <button type='submit' className='DashboardEdit-submit-btn'>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default DashboardEdit;

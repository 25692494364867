import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../styles/DashboardMember.css";
import { Link } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import imageUploadIcon from "../assets/imageUploadIcon.png";
import { UserMinus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Home } from "lucide-react";
import Loading from "../components/Loading";
import Logo from "../assets/mainLogo.png";

//optimisatoins
const DashboardMember = () => {
  const navigate = useNavigate();

  const { memid } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [memberData, setMemberData] = useState({
    name: "",
    contact: "",
    email: "",
    address: "",
    gender: "",
    emergencyContact: "",
    id: "",
    startDate: "",
    endDate: "",
    membershipPlan: "",
    image: null,
  });

  useEffect(() => {
    const currMem = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/gym/show-member-by-id/${memid}`
        );
        setMemberData(res.data.member);
        setLoading(false);
        console.log(res);
      } catch (error) {
        console.error("Error fetching member data:", error);
        setLoading(false);
      }
    };
    currMem();
  }, [memid]);

  const handleDelete = async () => {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/gym/delete-member/${memid}`
    );
    navigate("/members-information");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/gym/update-member/${memid}`,
        memberData
      );
      toast.success("Member data updated successfully!", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
      console.log("Member data updated successfully!");
      setLoadingForm(false);
      //toast
    } catch (error) {
      toast.error("Error updating data!", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
      console.error("Error updating data:", error);
      setLoadingForm(false);
      //toast
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setMemberData({ ...memberData, image: selectedImage });
  };

  if (loading) {
    return (
      <>
        <Loading />
      </>
    ); //WIP Loading Animation
  }

  return (
    <>
      <div className='dm-main'>
        <div className='dm-nav'>
          <div className='dm-navtop'>
            <Link to='/'>
              <img src={Logo} alt='Logit' id='dm-logo' />
            </Link>
            <h1 id='dm-navtop-h1'> Dashboard </h1>
            <button
              id='dm-navtop-logout'
              onClick={() => {
                document.cookie =
                  "gymId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                return navigate("/");
              }}
            >
              Logout
            </button>
          </div>
          <div className='dm-navbottom'>
            <div className='dm-navbottom-left'>
              <div>
                <img
                  src={memberData.displayImage}
                  alt='profile'
                  id='dm-navbottom-left-image'
                />
              </div>
              <span id='dm-navbottom-left-name'>{memberData.name}</span>
              <div>
                <span
                  id='dm-navbottom-left-edit'
                  onClick={() => {
                    const addMemberForm =
                      document.getElementById("dm-bottom-form");
                    addMemberForm.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Edit
                </span>
              </div>
            </div>
            <div className='dm-navbottom-right'>
              <div className='dm-navbottom-right-top'>
                <div className='dm-navbottom-right-top-id-main'>
                  <span id='dm-navbottom-right-top-id'> ID</span>
                  <span id='dm-navbottom-right-top-id-value'>
                    {memberData.id}
                  </span>
                </div>
                <div className='dm-navbottom-right-top-contact-main'>
                  <span id='dm-navbottom-right-top-contact'> Contact</span>
                  <span id='dm-navbottom-right-top-contact-value'>
                    {memberData.contact}
                  </span>
                </div>
                <div className='dm-navbottom-right-top-email-main'>
                  <span id='dm-navbottom-right-top-email'> Email</span>
                  <span id='dm-navbottom-right-top-email-value'>
                    {memberData.email}
                  </span>
                </div>
                <div className='dm-navbottom-right-top-emergency-main'>
                  <span id='dm-navbottom-right-top-emergency'>
                    {" "}
                    Emergency No.
                  </span>
                  <span id='dm-navbottom-right-top-emergency-value'>
                    {memberData.emergencyContact}
                  </span>
                </div>
              </div>
              <div className='dm-navbottom-right-bottom'>
                <div className='dm-navbottom-right-bottom-plan-main'>
                  <span id='dm-navbottom-right-bottom-id'> Plan</span>
                  <span id='dm-navbottom-right-bottom-id-value'>
                    {memberData.membershipPlan}
                  </span>
                </div>
                <div className='dm-navbottom-right-bottom-startDate-main'>
                  <span id='dm-navbottom-right-bottom-startDate'>
                    {" "}
                    Start Date
                  </span>
                  <span id='dm-navbottom-right-bottom-startDate-value'>
                    {memberData.startDate}
                  </span>
                </div>
                <div className='dm-navbottom-right-bottom-endDate-main'>
                  <span id='dm-navbottom-right-bottom-endDate'> End Date</span>
                  <span id='dm-navbottom-right-bottom-endDate-value'>
                    {memberData.endDate}
                  </span>
                </div>
                <div className='dm-navbottom-right-bottom-address-main'>
                  <span id='dm-navbottom-right-bottom-address'> Address</span>
                  <span id='dm-navbottom-right-bottom-address-value'>
                    {memberData.address}
                  </span>
                </div>
              </div>
            </div>
            <UserMinus onClick={handleDelete} size={30} id='dm-idminus' />
            {/*size of icon*/}
          </div>
        </div>
        <div className='dm-middle'>
          <Link
            to={`/member-attendance-information/${memid}`}
            style={{ textDecoration: "none" }}
          >
            <div className='dm-middle-attendance-button'>
              Attendance Details
            </div>
          </Link>
          <Link
            to={`/member-fees-information/${memid}`}
            style={{ textDecoration: "none" }}
          >
            <div className='dm-middle-payment-button'>Payment Details</div>
          </Link>
        </div>
        <div
          style={{
            flex: 1,
            height: "1px",
            backgroundColor: "gray",
            margin: "40px 0px",
          }}
        />
        <div className='dm-bottom'>
          <h2 id='dm-bottom-title'>Edit Member Information</h2>
          <form
            className='dm-bottom-form'
            onSubmit={handleFormSubmit}
            id='dm-bottom-form'
          >
            <div className='dm-bottom-form-img'>
              <div
                className='dm-bottom-form-imgdiv'
                onClick={() =>
                  document.getElementById("dm-bottom-image").click()
                }
              >
                {memberData?.displayImage ? (
                  <img
                    src={memberData?.displayImage}
                    alt='Gym Logo'
                    className='dm-bottom-form-img-preview'
                  />
                ) : (
                  <img
                    src={imageUploadIcon}
                    alt='Upload Icon'
                    className='dm-bottom-img-upload-icon'
                  />
                )}
              </div>
              <input
                type='file'
                accept='image/*'
                id='dm-bottom-image'
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
            <div className='dm-bottom-form-input-group-name'>
              <label htmlFor='dm-bottom-form-name' id='dm-bottom-form-nameSpan'>
                Name
              </label>
              <input
                type='text'
                id='dm-bottom-form-name'
                placeholder='Enter a name'
                value={memberData.name}
                onChange={(e) =>
                  setMemberData({ ...memberData, name: e.target.value })
                }
                required
              />
            </div>
            <div className='dm-bottom-form-input-group-contact'>
              <label
                htmlFor='dm-bottom-form-contact'
                id='dm-bottom-form-contactSpan'
              >
                Contact
              </label>
              <PhoneInput
                id='dm-bottom-form-contact'
                placeholder='Enter a contact number'
                value={memberData.contact}
                defaultCountry='IN'
                onChange={(value) =>
                  setMemberData({ ...memberData, contact: value })
                }
                maxLength='11'
                minLength='11'
                required
              />
            </div>
            <div className='dm-bottom-form-input-group-email'>
              <label
                htmlFor='dm-bottom-form-email'
                id='dm-bottom-form-emailSpan'
              >
                Email
              </label>
              <input
                type='email'
                id='dm-bottom-form-email'
                placeholder='Enter a email'
                value={memberData.email}
                onChange={(e) =>
                  setMemberData({ ...memberData, email: e.target.value })
                }
                required
              />
            </div>
            <div className='dm-bottom-form-input-group-address'>
              <label
                htmlFor='dm-bottom-form-address'
                id='dm-bottom-form-addressSpan'
              >
                Address
              </label>
              <input
                type='text'
                id='dm-bottom-form-address'
                placeholder='Enter an address'
                value={memberData.address}
                onChange={(e) =>
                  setMemberData({ ...memberData, address: e.target.value })
                }
                required
              />
            </div>
            <div className='dm-bottom-form-input-group-emergency_contact'>
              <label
                htmlFor='dm-bottom-form-emergency_contact'
                id='dm-bottom-form-emergency_contactSpan'
              >
                Emergency Contact
              </label>
              <PhoneInput
                id='dm-bottom-form-emergency_contact'
                placeholder='Enter an emergency contact number'
                value={memberData.emergencyContact}
                defaultCountry='IN'
                onChange={(value) =>
                  setMemberData({ ...memberData, emergencyContact: value })
                }
                maxLength='11'
                minLength='11'
                required
              />
            </div>
            <div className='dm-bottom-form-input-group-id'>
              <label htmlFor='dm-bottom-form-id' id='dm-bottom-form-idSpan'>
                ID
              </label>
              <input
                type='text'
                id='dm-bottom-form-id'
                placeholder='Enter an ID'
                value={memberData.id}
                onChange={(e) =>
                  setMemberData({ ...memberData, id: e.target.value })
                }
                required
              />
            </div>
            <div className='dm-bottom-form-input-group-gender'>
              <label
                htmlFor='dm-bottom-form-gender'
                id='dm-bottom-form-genderSpan'
              >
                Gender
              </label>
              <select
                value={memberData.gender}
                id='dm-bottom-form-gender'
                onChange={(e) =>
                  setMemberData({ ...memberData, gender: e.target.value })
                }
                required
              >
                <option value=''>Select Gender</option>
                <option value='MALE'>Male</option>
                <option value='FEMALE'>Female</option>
                <option value='OTHERS'>Others</option>
              </select>
            </div>
            <div className='dm-bottom-form-input-group-start-date'>
              <label
                htmlFor='dm-bottom-form-start-date'
                id='dm-bottom-form-startDateSpan'
              >
                Start Date
              </label>
              <input
                type='date'
                id='dm-bottom-form-start-date'
                value={memberData.startDate}
                onChange={(e) =>
                  setMemberData({ ...memberData, startDate: e.target.value })
                }
                required
              />
            </div>
            <div className='dm-bottom-form-input-group-end-date'>
              <label
                htmlFor='dm-bottom-form-end-date'
                id='dm-bottom-form-endDateSpan'
              >
                End Date
              </label>
              <input
                type='date'
                id='dm-bottom-form-end-date'
                value={memberData.endDate}
                onChange={(e) =>
                  setMemberData({ ...memberData, endDate: e.target.value })
                }
                required
              />
            </div>
            <div className='dm-bottom-form-input-group-membership'>
              <label
                htmlFor='dm-bottom-form-membership'
                id='dm-bottom-form-membershipSpan'
              >
                Membership Plan
              </label>
              <select
                value={memberData.membershipPlan}
                id='dm-bottom-form-membership'
                onChange={(e) =>
                  setMemberData({ ...memberData, membership: e.target.value })
                }
                required
              >
                <option value=''>Select a Plan</option>
                <option value='monthly'>Monthly</option>
                <option value='quarterly'>Quarterly</option>
                <option value='half_yearly'>Half Yearly</option>
                <option value='yearly'>Yearly</option>
              </select>
            </div>
            <div>
              {loadingForm ? (
                <>
                  <Loading />
                </>
              ) : (
                <button type='submit' id='dm-bottom-form-submitButton'>
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DashboardMember;

import React from "react";
import axios from "axios";
import DustbinIcon from "../assets/DustbinIcon.png";
import "../styles/OfferInformation.css";

const OfferCard = ({
  startDate,
  endDate,
  offerDuration,
  offerPrice,
  onDelete,
}) => {
  const handleDelete = async () => {
    try {
      // Assuming the notice can be identified by the combination of issueDate, effectiveDate, and noticeDetails
      const offerIdentifier = { startDate, endDate, offerDuration, offerPrice };

      // Call the backend to delete the notice using noticeIdentifier
      await axios.delete("http://localhost:5000/api/deleteOffer", {
        data: offerIdentifier,
      });

      // On successful deletion, trigger onDelete to update the frontend state
      onDelete(offerIdentifier);
    } catch (error) {
      console.error("Error deleting offer:", error);
    }
  };
  return (
    <div className="Offer-card">
      <div className="OfferCard-main">
        <span>{startDate}</span>
        <span>{endDate}</span>
        <span>{offerDuration}</span>
        <span>{offerPrice}</span>
        <img src={DustbinIcon} alt="Delete" onClick={handleDelete} />
      </div>
    </div>
  );
};

export default OfferCard;

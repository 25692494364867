import { React, useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Member from "../Assets/Member.png";
import { Link as RouterLink } from "react-router-dom";

const MemberNavbar = () => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // You can adjust the scroll threshold (e.g., 100) as needed
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClass = isNavbarFixed
    ? "memberlogin-navbar fixed-memberlogin-navbar"
    : "memberlogin-navbar";

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <nav className={navbarClass}>
      <div className="memberlogin-left">
        <img
          src={Member}
          alt="Logo"
          id="memberlogin-navLogo"
          onClick={scrollToTop}
        />
      </div>
      <div className="memberlogin-middle">
        <Link to="home" spy={true} smooth={true} offset={-130} duration={500}>
          Home
        </Link>
        <Link
          to="services"
          spy={true}
          smooth={true}
          offset={-130}
          duration={500}
        >
          Services
        </Link>
        <Link to="about" spy={true} smooth={true} offset={-130} duration={500}>
          About Us
        </Link>
        <Link
          to="connect"
          spy={true}
          smooth={true}
          offset={-108}
          duration={500}
        >
          Connect
        </Link>
      </div>
      <div className="memberlogin-right">
        <RouterLink to="/member/login" id="memberNavlogin">
          Login
        </RouterLink>
        <RouterLink to="/member/signup" id="memberNavsignup">
          Signup
        </RouterLink>
      </div>
    </nav>
  );
};
export default MemberNavbar;

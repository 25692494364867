import React, { useState, useEffect } from "react";
import AttendanceCard from "../components/AttendanceCard";
import axios from "axios";
import { getDayName } from "../lib/utils";
import Dashboard from "./Dashboard";
import { Link } from "react-router-dom";
import SevenDaysDistribution from "../components/SevenDayDistrinution";
import DaysDistribution from "../components/DaysDistribution";
import ShiftsChart from "../components/ShiftsCharts";
import Loading from "../components/Loading";
//optimisations
const Attendance = () => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [days7Chart, setDays7Chart] = useState([]);
  const [daysChart, setDaysChart] = useState([]);
  const [shiftsChart, setShiftsChart] = useState([]);
  const fetchAttendanceData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-gym-attendance`
        // `${process.env.REACT_APP_BASE_URL}/gym/show-gym-attendance-today`
      );
      setAttendanceData(response.data.flattenedAttendance.reverse());
      setLoading(false);
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchdistributionByDaysOfWeek = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/distribution-by-days-of-week`
      );
      console.log("chartData", res);
      setDaysChart(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetch7DaysCHartData = async () => {
    try {

      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/graph-seven-day-attendance`
      );
      setDays7Chart(res.data);
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
    }
  };
  const fetchShiftsChartsDats = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/distribution-by-evening-morning-shifts`
      );
      setShiftsChart(res.data);
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
    }
  };

  useEffect(() => {
    fetchAttendanceData();
    fetch7DaysCHartData();
    fetchdistributionByDaysOfWeek();
    fetchShiftsChartsDats();
  }, []);

  const filteredAttendance = attendanceData.filter((attendance) =>
    Object.values(attendance).some((field) =>
      field.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  return (
    <>
      <Dashboard />

      <div className='Attendance-container'>
        <div className='Attendance-header'>
          <h2 id='Attendance-header-title'>Attendance-</h2>
          <h3 id='Attendance-header-date'>{new Date().toLocaleDateString()}</h3>
        </div>
        <div className='AttendanceCards-container'>
          <div className='Attendance-searchBar'>
            <input
              type='text'
              placeholder='Search Here'
              id='Attendance-searchBar-input'
              className='Search-input'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className='Attendance-list'>
            <span></span>
            <span id="Attendance-list-name">Name</span>
            <span id="Attendance-list-id">ID</span>
            <span id="Attendance-list-time">Time</span>
            <span id="Attendance-list-day">Day</span>
            <span id="Attendance-list-date">Date</span>
          </div>
          <div className='Attendance-list-main'>
            {loading ? (
              <><Loading/></> //loader WIP ANIMATED PART
            ) : (
              filteredAttendance.map((attendance, index) =>
                new Date(attendance.createdAt).toLocaleDateString() ===
                new Date().toLocaleDateString() ? (
                  <Link
                    to={`/member-information/${attendance.memberId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <AttendanceCard
                      key={index}
                      imageSrc={attendance.memberId}
                      name={attendance.name}
                      id={attendance.id}
                      time={new Date(attendance.updatedAt).toLocaleTimeString()}
                      day={getDayName(new Date(attendance.updatedAt))}
                      date={new Date(attendance.createdAt).toLocaleDateString()}
                    />
                  </Link>
                ) : (
                  <></>
                )
              )
            )}
          </div>
        </div>
        <div className='Attendance-statistics'>
          <h2 id='Attendance-statistics-header'>Attendance Statistics</h2>
          <div className='Attendance-graphs'>
            <div className='graph-7day-attendance'>
              <SevenDaysDistribution data={days7Chart} />
            </div>
            <div className='graph-7day-attendance'>
              <ShiftsChart data={shiftsChart} />
            </div>
            <div className='graph-morning_evening-attendance'>
              <DaysDistribution data={daysChart} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance;

import { React, useState, useEffect } from "react";
import services_attendanceDetails from "../Assets/services_attendanceDetails.png";
import services_feesDetails from "../Assets/services_feesDetails.png";
import services_membersDetails from "../Assets/services_membersDetails.png";
import services_paymentDetails from "../Assets/services_paymentDetails.png";
import services_exploreGyms from "../Assets/services_exploreGyms.png";
import services_notices from "../Assets/services_notices.png";
import MemberServiceCard from "../MemberComponents/MemberServiceCard";
import face2 from "../Assets/face2.jpeg";

const details = [
  {
    logo: services_attendanceDetails,
    title: "Attendance Details",
    description:
      "We help you in recording, storing and accessing your attendance records.",
  },
  {
    logo: services_feesDetails,
    title: "Fees Details",
    description:
      "We remember and remind you of your fee payments so you can focus of grinding.",
  },
  {
    logo: services_paymentDetails,
    title: "Payments Details",
    description:
      "We help you in storing and accessing your gym payment details.",
  },
  {
    logo: services_notices,
    title: "Notice",
    description:
      "We help you in staying connected to your gym by showing all updates from your gym.",
  },
  {
    logo: services_membersDetails,
    title: "Complete Details",
    description:
      "We help you in creating and maintaining a proper gym profile.",
  },
  {
    logo: services_exploreGyms,
    title: "Explore Gyms",
    description: "We have a plenty of options for you to explore.",
  },
];

const MemberServices = () => {
  return (
    <>
      <div className="member-serviceCard-header">How we can help you</div>
      <div className="member-ServiceCards">
        {details.map((detail, index) => (
          <MemberServiceCard
            key={index}
            logo={detail.logo}
            title={detail.title}
            description={detail.description}
          />
        ))}
      </div>
      <div className="member-serviceCard-footer1">
        & many more once you partner with us
      </div>
    </>
  );
};

export default MemberServices;

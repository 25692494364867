export const getAttendanceByDateRange = (attendanceArray) => {
  const currentDate = Date.now();
  const sevenDaysAgo = currentDate - 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

  const filteredAttendance = [];

  for (const attendance of attendanceArray) {
    const updatedAtTimestamp = new Date(attendance.updatedAt).getTime();
    if (
      updatedAtTimestamp >= sevenDaysAgo &&
      updatedAtTimestamp <= currentDate
    ) {
      filteredAttendance.push(attendance);
    }
  }

  return filteredAttendance;
};

export const getDayName = (date) => {
  const dayOfWeek = date.getDay();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return daysOfWeek[dayOfWeek];
};

export const calculateDueDateStatus = (
  gymData,
  memberResponse,
  gymResponse
) => {
  const gym = gymResponse.data;
  const members = memberResponse.members;

  if (!members) {
    throw new Error("Members data is undefined");
  }

  const dueList = members.map((member) => {
    const endDate = new Date(member.endDate);
    const currentDate = new Date();

    console.log("endDate:" , `${member.name}`, endDate);
    console.log("currentDate:",`${member.name}`, currentDate);

    const timeDifference = endDate.getTime() - currentDate.getTime();
    console.log("timeDifference:", `${member.name}`, timeDifference);

    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    console.log("daysDifference:", `${member.name}`, daysDifference);

    let status = "";
    if (daysDifference <= 0) {
      status = "Passed due date";
    } else if ( daysDifference > 0 & daysDifference <= 3 ) {
      status = "Due within 3 days";
    } else {
      status = "No worries for now";
    }

    return {
      member: {
        _id: member._id,
        name: member.name,
        id: member.id,
        endDate: member.endDate,
      },
      status: status,
    };
  });

  return dueList;
};

import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import redDot from "../assets/red-dot.png";
import greenDot from "../assets/green-dot.png";
import { calculateDueDateStatus } from "../lib/utils";
import FeeDueListCard from "../components/FeeDueListCard";
import "../styles/FeeDueList.css";
import Dashboard from "./Dashboard";
import { Link } from "react-router-dom";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/gym`;
axios.defaults.withCredentials = true;

const FeeDueList = () => {
  const [state, setState] = useState({
    dueList: [],
    loading: false,
    error: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({ ...prevState, loading: true }));
      try {
        const [gymResponse, memberResponse, gymData] = await Promise.all([
          axios.get("/show-gym-collection"),
          axios.get("/show-members"),
          axios.get("/show-gym"),
        ]);

        const calculatedDueList = calculateDueDateStatus(
          gymData.data,
          memberResponse.data,
          gymResponse.data
        );

        setState({ dueList: calculatedDueList.reverse(), loading: false, error: null });
      } catch (error) {
        setState({ dueList: [], loading: false, error: error.message });
        console.error("Error fetching fee due list:", error);
      }
    };

    fetchData();
  }, []);

  const { dueList, loading, error } = state;

  const filteredDueList = useMemo(() => {
    return dueList
    // .filter(due => due.status !== "No worries for now");
  }, [dueList]);

  return (
    <>
      <Dashboard />
      <h2 id="FeeDueList-header-title">Fees Due List</h2>
      <div className="FeeDueListCard-container">
        <div className="FeesDueList-row">
          <span>Name</span>
          <span id="FeesDueListCard-id">ID</span>
          <span id="FeesDueListCard-endDate">End Date</span>
          <span id="FeesDueListCard-status">Status Message</span>
          <span>Status</span>
        </div>
        <div className="FeeDueList-main">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            filteredDueList.map((due, index) => (
              <Link
                to={`/member-information/${due.member._id}`}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <FeeDueListCard
                  name={due.member.name}
                  id={due.member.id}
                  endDate={new Date(due.member.endDate).toLocaleDateString()}
                  status={due.status}
                />
              </Link>
            ))
          )}
        </div>
        <div className="FeeDueList-footer">
          <div>
            <img src={greenDot} alt="greenDot" /> Due within 3 days
          </div>
          <div>
            <img src={redDot} alt="redDot" /> Due for more than 3 days
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeDueList;

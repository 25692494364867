import React from "react";

const ProfileCard = ({ ePic, name, description }) => {
  return (
    <div className="profileCard-main">
      <div className="profileCard-top">
        <img src={ePic} alt="pic" id="profileCard-img" />
      </div>
      <div className="profileCard-middle">{name}</div>
      <div className="profileCard-bottom">
        <p id="profileCard-description">{description}</p>
      </div>
    </div>
  );
};

export default ProfileCard;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import IndividualMemberAttendanceCard from "./IndividualMemberAttendanceCard";
import "../styles/IndividualMemberAttendance.css";
import { getDayName } from "../lib/utils";
import MemberDashboardOwnerInfo from "./MemberDashboardOwnerInfo";

const IndividualMemberAttendance = () => {
  const { memId } = useParams();
  const [attendances, setAttendances] = useState([]);

  const fetchAttendanceData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-member-attendance/${memId}`
      );
      setAttendances(response.data.memberAttendances);
    } catch (error) {
      console.log("Error Fetching Data:", error);
    }
  };

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  return (
    <>
      <MemberDashboardOwnerInfo memid={memId} />

      <div className="ima-container">
        <div className="ima-row">
          <span id="ima-row-name"> Name </span>
          <span id="ima-row-id"> ID </span>
          <span id="ima-row-time"> Time </span>
          <span id="ima-row-day"> Day </span>
          <span id="ima-row-date"> Date </span>
        </div>

        <div className="ima-attendance-main">
          {attendances && attendances.length > 0 ? (
            attendances.map((attendance, index) => (
              <IndividualMemberAttendanceCard
                key={index}
                imageSrc={attendance.memberId}
                name={attendance.name}
                id={attendance.id}
                time={new Date(attendance.updatedAt).toLocaleTimeString()}
                day={getDayName(new Date(attendance.updatedAt))}
                date={new Date(attendance.createdAt).toLocaleDateString()}
              />
            ))
          ) : (
            <p>No attendance data</p>
          )}
        </div>
      </div>
    </>
  );
};

export default IndividualMemberAttendance;

import React from "react";
import logo from "../assets/logo.png";

import "../styles/Maboutus.css";


import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import CEO from "../assets/CEO.png";
import CTO from "../assets/CTO.png";


const AboutUS = () => {
  return (
   
      <div>
        <img
          className='about-us'
         
        />
        <h1 className='h1'>About Us</h1>
        <p className='p'>We are your go-to gym partner</p>
        <b className='signup'>Signup</b>
        <div className='login-button'></div>
        <b className='login'>Login</b>

        <div className="header-items">
                <a href="#" className="header-item1">Home</a>
                <a href="#" className="header-item2">Services</a>
                <a href="#" className="header-item3">About Us</a>
                <a href="#" className="header-item4">Connect</a>
                <a href="#" className="header-item5">Pricing</a>
                <a href="#" className="header-item6">Blogs</a>
            </div>

        <div className='white-container'></div>
        <h1 className='sec2'>What is Logit?</h1>
        <div className='flex-container'>
          <div className='flex-item1'>
            <div className='p1'>
              <p>
              Imagine doing this: You walk into the gym, ready to conquer your workout. But then the struggle begins. You wait in line to mark your attendance. But to your surprise, your membership has also expired and now you have to renew it first to finally start that world conquering workout.
              </p>
              <p>
              Now imagine this: You breeze into the gym, phone in hand. A quick scan of the QR code marks your attendance. You already know that membership in not ending anytime soon. You head to your workout confidently.
              </p>
            </div>
            <div className='p2'>
            <p>
            <b>Effortless check-ins</b><br></br>
            Logit uses a simple QR code system. Just scan it and you are good to go. No more waiting in line to sign the attendance sheet.
            </p>
            <p>
            <b>Transparent Fees</b><br></br>
            No more keeping in mind or getting stopped from behind for fee payments. Your gym partner keeps track of that for you. 
            </p>
            <p>
            <b>Communication</b><br></br>
           Your trusted gym partner keeps you informed about upcoming class cancellations or any major updates from your gym.
            </p>
          <p>
          <b>Streak worth maintaining</b><br></br>
            A streak starts from the day you first mark your attendance and it keeps on growing as you stay committed to your fitness goals. Definitely a streak worth maintaining.
          </p>

          <p>
            <b>
            Logit is essentially your partner ( only workout, not your life partner ) which handles everything for you except for the workout. This translates a smoother and more motivating experience for you. So ditch the hassle and focus on what matter most - getting fit and having fun.
            </b>
          </p>
            </div>
          </div>
        </div>

        <div className='white-container'></div>
        <h1 className='sec3'>What is our vision with LOGIT?</h1>
        <div class='flex-container2'>
          <div class='flex-item2'>
            <img src={img2} alt='' />
          </div>
          <div class='flex-item3'>
            <p>
            Even a weightlifting warrior was once a treadmill newbie and was always afraid of getting judged by others. From that first nervous walk into the gym to getting the dream figure, nobody ever judged you.</p> 

            <p>You are wrong if you think you are alone in this journey, we are here to cheer you up and share some celebratory high-fives online.
            </p>
            <p>
              <b>
              We envisioned a community to empower everyone at any stage of their fitness journey by making their journey enjoyable and encouraging a sense of achievement through seamless online tracking, interaction  and a source of endless inspiration.
              </b>
            </p>
          </div>
        </div>
        <div className='white-container'></div>
        <h1 className='sec4'>What is our aim with LOGIT?</h1>
        <div class='flex-container3'>
          
          <div className='flex-item5'>
            <p>
            Everyone from a weightlifting warrior to a treadmill newbie loves to watch their hard work paying off through clear visuals and data. This is where Logit comes in for you. It’s like a personal cheerleader in your pocket, constantly reminding you how far you have come.</p><br></br>

             <p>But we aim to be more than just a tracker.  We want you to be a part of the most welcoming, super enthusiastic community and connect with like minded individuals. Share your struggles, celebrate their victories and get inspired.</p><br></br>

             <p><b>Our goal is to make you feel appreciated and well-connected to the world of fitness because achieving your goals is way more fun when you have people around you to celebrate it with you, for you.</b></p>
            
          </div>
          <div className='flex-item6'>
            <img src={img1} alt='' />
          </div>
        </div>
        <div className='white-container'></div>
        <h1 className='sec5'>The Founders</h1>
        <div class='flex-container4'>
          <div className='flex-item7'>
            <img src={CEO} alt='' />
          </div>
          <div className='flex-item8'>
            <p>
            We built Logit because we know the fitness journey can feel lonely sometimes. We wanted to create a platform where everyone from beginner to gym rat, feels supported and connected. Logit is more than just a tracking software, it’s a place where you can share your wins, find encouragement and learn from others. We know that a strong support system is key to achieving your fitness goals and Logit is here to be your cheerleader squad at every step of the way. 
            We will make your fitness journey as exciting and thrilling as hitting your 2nd PR in one week.
            </p>
          </div>
          <div className='flex-item9'>
            <h1>Pratham Aggarwal</h1>
          </div>
        </div>
        <div className='white-container2'></div>
        
        <div class='flex-container4'>
          <div className='flex-item11'>
            <img src={CTO} alt='' />
          </div>
          <div className='flex-item12'>
            <p>
            We believe fitness shouldn’t be a chore rather it should be enjoyable and interactive. We will help you personalize your goals and track your progress in a way that highlights your personal achievement. Every step forward, from that first workout to finally mastering a new exercise, will be a win. Logit will be there to cheer you on and keep you motivated, making your journey a celebration as Logit celebrated YOU!! 
            We will make your fitness journey enjoyable, interactive, and full of personal achievements.
            </p>
          </div>
          <div className='flex-item13'>
            <h1>Suman Saurav</h1>
          </div>
        </div>
      </div>
    
  );
};
export default AboutUS;

import React from "react";
import redDot from "../assets/red-dot.png";
import greenDot from "../assets/green-dot.png";
import orangeDot from "../assets/orangeDot.webp";

const FeeDueListCard = ({ name, id, endDate, status }) => {

  let dotImage;
  if (status === "No worries for now") {
    dotImage = greenDot;
  } else if (status === "Due within 3 days") {
    dotImage = orangeDot;
  }else{
    dotImage = redDot
  }

  return (
    <div className="FeeDueListCard-main">
      <span id="FeeDueListCard-main-name">{name}</span>
      <span id="FeeDueListCard-main-id">{id}</span>
      <span id="FeeDueListCard-main-endDate">{endDate}</span>
      <span id="FeeDueListCard-main-status">{status}</span>
      <img
        src={dotImage}
        alt={
          status !== "No worries for now"
            ? "Payment overdue"
            : `Pay fees`
        }
        id="FeeDueListCard-main-dot"
      />
    </div>
  );
};

export default FeeDueListCard;

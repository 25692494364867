import React from "react";
import axios from "axios";
import "../MemberStyles/MemberNotice.css";
const NoticeCard = ({
  issueDate,
  effectiveDate,
  details,
  onDelete,
  noticeId,
}) => {
  const handleDelete = async () => {
    try {
      const noticeIdentifier = { issueDate, effectiveDate, details };

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/gym/delete-gym-notice/${noticeId}`,
        {
          data: noticeIdentifier,
        }
      );

      onDelete(noticeIdentifier);
    } catch (error) {
      console.error("Error deleting notice:", error);
    }
  };

  return (
    <div className="mb-Notice-card">
      <div className="mb-NoticeCard-main">
        <span id="mb-NoticeCard-id">{issueDate}</span>
        <span id="mb-NoticeCard-ed">{effectiveDate}</span>
        <p id="mb-NoticeCard-para">{details}</p>
      </div>
    </div>
  );
};

export default NoticeCard;

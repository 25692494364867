import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { auth, provider } from "../firebase/config";
import { signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";
import "../styles/Login.css";
import mainLogo from "../assets/mainLogo.png";
import Logo from "../assets/Google__G__Logo.svg";
import tagline1 from "../assets/tagline1.png";
import tagline2 from "../assets/tagline2.png";
import tagline3 from "../assets/tagline3.png";
import tagline4 from "../assets/tagline4.png";
import tagline5 from "../assets/tagline5.png";
import tagline6 from "../assets/tagline6.png";
import tagline7 from "../assets/tagline7.png";
import tagline8 from "../assets/tagline8.png";

//refactoring and formating optimisations are welcomed
const slideshowImages = [
  tagline1,
  tagline2,
  tagline3,
  tagline4,
  tagline5,
  tagline6,
  tagline7,
  tagline8,
];
function Login() {
  axios.defaults.withCredentials = true;
  const [navi, setNavi] = useState("");
  const [formLoad, setFormLoad] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const changeImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % slideshowImages.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(changeImage, 2000); // changing image every 2 seconds
    return () => clearInterval(intervalId);
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [redirectToGymSetup, setRedirectToGymSetup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // authentication logic
    setFormLoad(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signin`,
        formData
      );
      setFormLoad(false);
      if (response.status === 200) {
        toast.success("Logged In ", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: false,
        });
        try {
          const isGymAvailable = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/gym/show-gym`
          );
          setNavi("/dashboard");
        } catch (e) {
          console.log(e.message);
          setNavi("/gymsetup");
        }

        setRedirectToGymSetup(true);
      }
    } catch (error) {
      toast.error("Login Failed", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    }
  };

  if (redirectToGymSetup) {
    return <Navigate to={navi} />;
  }
  const toggeleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const registerWithGoogle = async () => {
    try {
      const data = await signInWithPopup(auth, provider);
      console.log(data);
      handleGoogleSignIn(data);
    } catch (err) {
      console.log("haw oAuth Error", err);
    }
  };
  const handleGoogleSignIn = async (data) => {
    try {
      setFormLoad(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/google`,
        data
      );
      setFormLoad(false);
      if (res.status === 200) {
        try {
          const isGymAvailable = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/gym/show-gym`
          );
          setNavi("/dashboard");
        } catch (e) {
          console.log(e.message);
          setNavi("/gymsetup");
        }

        setRedirectToGymSetup(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-left-half">
        <div className="logo">
          <a href="/">
            {" "}
            <img src={mainLogo} alt="Logit" id="login-mainLogo" />
          </a>
        </div>
        <h1 id="login-left-half-h1">
          Welcome to,
          <br /> Logit <span id="login-for">for</span> Business
        </h1>
        <h2 id="login-Where-we">Where we...</h2>
        <div className="login-slideshow">
          <img
            src={slideshowImages[currentImageIndex]}
            alt="Slideshow"
            className="login-slideshow-image"
          />
        </div>
      </div>
      <div className="login-right-half">
        <div className="login-right-half-top">
          <h2 id="login-right-half-h2">
            Login <br />
            <p className="login-b-text">
              to change your gym management experience forever
            </p>
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-form-group">
            <label
              htmlFor="login-emailInput"
              id="login-Email"
              className="login-label"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="login-emailInput"
              placeholder="Please Enter Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="login-form-group">
            <label
              htmlFor="login-passwordInput"
              id="login-Password"
              className="login-label"
            >
              Password
            </label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="login-passwordInput"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          {formLoad ? (
            <div id="login-submit">LoggingIn...</div> //WIP Loading animation
          ) : (
            <button type="submit" id="login-submit">
              Login
            </button>
          )}
          <span>
            <a href="/forgotPassword" id="login-forgot-pswd">
              {" "}
              Forgot Password?
            </a>
          </span>
          <br />
          <span id="login-Or">Or</span>
          <br />
          <button onClick={registerWithGoogle} id="login-googleSignin">
            <img src={Logo} alt="Google Logo" id="googleLogo" />
            <span id="Google-text">Continue with Google</span>
          </button>
        </form>
        <div className="login-signup-link">
          Not registered? <Link to="/signup">Create an account</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;

import React from "react";

const IndividualMemberPaymentCard = ({ amount, time, date, mode }) => {
  return (
    <div className="impc-main">
      <span id="impc-amount">{amount}</span>
      <span id="impc-time">{date}</span>
      <span id="impc-date">{time}</span>
      <span id="impc-mode">{mode}</span>
    </div>
  );
};

export default IndividualMemberPaymentCard;

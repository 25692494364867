import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/Dashboard.css";
import DashboardCard from "../components/DashboardCard";
import Dashboardpannel from "../components/Dashboardpannel";
import { details } from "../lib/dashboardIcons";
import Loading from "../components/Loading";

const Dashboard = () => {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(true);
  const [gymData, setGymData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGymData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/gym/show-gym`);
        console.log("Axios Response:", response);
        setGymData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching gym data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchGymData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Dashboardpannel
        qrData={gymData?.gym?.qrCode}
        displayImage={gymData?.gym?.displayImage}
        gymName={gymData?.gym?.name}
        ownerName={gymData?.gym?.ownerDetails?.name}
      />

      <div className="Dashboard-middle">
        {details.map((detail, index) => (
          <DashboardCard
            key={index}
            DashboardLogo={detail.logo}
            DashboardInformation={detail.DashboardInformation}
            link={detail.link}
          />
        ))}
      </div>
    </>
  );
};

export default Dashboard;

import React from "react";
import PricingCard from "./PricingCard";
import price1 from "../assets/price1.png";
import price2 from "../assets/price2.png";
import price3 from "../assets/price3.png";
import price4 from "../assets/price4.png";

const details = [
  {
    logo: price1,
    title: "Short-Term",
    price: "Rs. 20",
    member: "per member",
    description1: "\u25cf 1 month",
    description2: "\u25cf Better for gyms with 75 or less members",
    description3:
      "\u25cf Want to try for 1 month but afraid of long term commitment",
  },
  {
    logo: price2,
    title: "Triple Play",
    price: "Rs. 18",
    member: "per member",
    description1: "\u25cf 3 months",
    description2:
      "\u25cf Better for gyms with more than 75 and less than 125 members",
    description3: "\u25cf Convinced to grow their business with us",
  },
  {
    logo: price3,
    title: "Half-Year Hero",
    price: "Rs. 16",
    member: "per member",
    description1: "\u25cf 6 months",
    description2:
      "\u25cf Better for gyms with more than 125 and less than 175 members",
    description3: "\u25cf Convinced to grow their business with us",
  },
  {
    logo: price4,
    title: "MVP",
    price: "Rs. 15",
    member: "per member",
    description1: "\u25cf 12 months",
    description2: "\u25cf Better for gyms with more than 175 members",
    description3: "\u25cf Convinced to grow their business with us",
  },
];

const Pricing = () => {
  return (
    <div className="pricing-main">
      <div className="pricing-top">
        <h2 id="pricing-top-title">Pick a plan to grow your Business</h2>
        <h3 id="pricing-top-freetrial">Free trial for 1 month</h3>
      </div>
      <div className="pricing-bottom">
        {details.map((detail, index) => (
          <PricingCard
            key={index}
            logo={detail.logo}
            title={detail.title}
            price={detail.price}
            member={detail.member}
            description1={detail.description1}
            description2={detail.description2}
            description3={detail.description3}
          />
        ))}
      </div>
    </div>
  );
};

export default Pricing;

import React from "react";

const MemberPaymentInformationCard = ({
  amount,
  date,
  time,
  mode,
  onDelete,
}) => {
  console.log(time);
  return (
    <div className="MemberPayment-card">
      <div className="MemberPaymentCard-main">
        <span>{amount}</span>
        <span>{date}</span>
        <span>{time}</span>
        <span>{mode}</span>
      </div>
    </div>
  );
};

export default MemberPaymentInformationCard;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { saveAs } from "file-saver";
import Logo from "../assets/mainLogo.png";
import "../styles/Dashboard.css";
import mainLogo from "../assets/mainLogo.png";
import QRDownload from "../assets/QRDownload.png";
import { Home } from "lucide-react";

const Dashboardpannel = ({ displayImage, gymName, ownerName, qrData }) => {
  const navigate = useNavigate();
  const [qrCode, setQRCode] = useState("");
  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-code-canvas");
    canvas.toBlob((blob) => {
      saveAs(blob, "gym_qr_code.png");
    });
  };

  return (
    <div className='Dashboard-navbar'>
      <div className='Dashboard-navtop'>
        <Link to='/'>
          <img src={Logo} alt='Logit' id='Dashboard-logo' />
        </Link>

        <h1 id='Dashboard-navtop-h1'> Dashboard </h1>
        <button
          id='Dashboard-navtop-logout'
          onClick={() => {
            return navigate("/");
          }}
        >
          Logout
        </button>
      </div>
      <div className='Dashboard-navmiddle'>
        <img src={displayImage} alt='Gym Logo' id='Dashboard-navmiddle-img' />
        <div className='Dashboard-navmiddle-texts'>
          <h1 id='Dashboard-navmiddle-h1'>{gymName}</h1>
          <span id='Dashboard-navmiddle-span'>{ownerName}</span>
          {qrData && (
            <div className='Dashboard-navmiddle-qr'>
              <div onClick={downloadQRCode}>
                <QRCode
                  fgColor='#1a293c'
                  bgColor='#ffffff'
                  includeMargin={false}
                  imageSettings={{
                    src: mainLogo,
                    height: 40,
                    width: 40,
                    excavate: true,
                  }}
                  size={200}
                  id='qr-code-canvas'
                  value={JSON.stringify(qrData)}
                />
                <img
                  src={QRDownload}
                  alt='Download'
                  id='Dashboard-navmiddle-download'
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='Dashboard-navbottom'>
        <a href='/dashboard-edit' id='Dashboard-navbottom-a'>
          Edit
        </a>
      </div>
    </div>
  );
};

export default Dashboardpannel;

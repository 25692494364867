import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../MemberStyles/MemberProfile.css";
import MemberDashboardComponent from "../MemberComponents/MemberDashboardComponent";

const MemberProfile = () => {
  const { memberId } = useParams();
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/gym/show-member-by-id/${memberId}`
        );
        setProfileData(response.data.member);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProfileData();
  }, [memberId]);

  return (
    <>
      <MemberDashboardComponent memberId={memberId} />
      <div className="member-profile-header">
        <h2 id="member-profile-header-title">Your Profile</h2>
      </div>
      {profileData && (
        <div className="member-details">
          <div className="profile-info">
            <div className="profile-image-container">
              <img
                src={profileData.displayImage}
                alt="Profile"
                className="profile-image"
              />
            </div>
            <div className="profile-name">
              <span id="pf-name">Name</span>
              <span>-</span>
              <span id="pf-name-data">{profileData.name}</span>
            </div>
            <div className="profile-id">
              <span id="pf-id">ID</span>
              <span>-</span>
              <span id="pf-id-data">{profileData.id}</span>
            </div>
            <div className="profile-contact">
              <span id="pf-contact">Contact</span>
              <span>-</span>
              <span id="pf-contact-data">{profileData.contact}</span>
            </div>
            <div className="profile-email">
              <span id="pf-email">Email</span>
              <span>-</span>
              <span id="pf-email-data">{profileData.email}</span>
            </div>
            <div className="profile-sd">
              <span id="pf-sd">Start Date </span>
              <span>-</span>
              <span id="pf-sd-data">{profileData.startDate}</span>
            </div>
            <div className="profile-ed">
              <span id="pf-ed">End Date</span>
              <span>-</span>
              <span id="pf-ed-data">{profileData.endDate}</span>
            </div>
            <div className="profile-mp">
              <span id="pf-mp">Membership Plan</span>
              <span>-</span>
              <span id="pf-mp-data">{profileData.membershipPlan}</span>
            </div>
            {/* You might want to format and display attendance and other fields as needed */}
          </div>
        </div>
      )}
    </>
  );
};

export default MemberProfile;

import React, { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import mainLogo from "../assets/mainLogo.png";
import { Link as RouterLink } from "react-router-dom";

const Navbar = () => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // You can adjust the scroll threshold (e.g., 100) as needed
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClass = isNavbarFixed ? "navbar fixed-navbar" : "navbar";

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <nav className={navbarClass}>
      <div className="left">
        <img src={mainLogo} alt="Logo" id="navLogo" onClick={scrollToTop} />
      </div>
      <div className="middle">
        <Link to="home" spy={true} smooth={true} offset={-130} duration={500}>
          Home
        </Link>
        <Link
          to="services"
          spy={true}
          smooth={true}
          offset={-130}
          duration={500}
        >
          Services
        </Link>
        <Link to="about" spy={true} smooth={true} offset={-130} duration={500}>
          About Us
        </Link>
        <Link
          to="connect"
          spy={true}
          smooth={true}
          offset={-108}
          duration={500}
        >
          Connect
        </Link>
      </div>
      <div className="right">
        <RouterLink to="/member" id="Navmember">
          Member
        </RouterLink>
        <RouterLink to="/login" id="Navlogin">
          Login
        </RouterLink>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";

const MemberAttendanceCard = ({ name, id, time, day, date }) => {
  return (
    <div className="member-attendance-card">
      <div className="member-attendanceCard-main">
        <span id="member-attendanceCard-main-name"> {name}</span>
        <span id="member-attendanceCard-main-id"> {id}</span>
        <span id="member-attendanceCard-main-time"> {time}</span>
        <span id="member-attendanceCard-main-day">{day}</span>
        <span id="member-attendanceCard-main-date"> {date}</span>
      </div>
    </div>
  );
};

export default MemberAttendanceCard;

import CEO from "../assets/CEO.png";
import CTO from "../assets/CTO.png";
export const details = [
    {
      profilePic: CEO,
      name: "Pratham Aggarwal",
      description:
        "We will make gym management as easy as lifting a 5 KG dumbell after 1 month of training.",
    },
    {
      profilePic: CTO,
      name: "Suman Saurav",
      description:
        "We will make gym management so light that even a 2.5 KG dumbell will feel heavier.",
    },
  ];
  
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const BarCharts = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  useEffect(() => {
    if (data) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");

      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Males", "Females", "Others"],

          datasets: [
            {
              label: "Gender Distribution",
              data: [data.males, data.females, data.others],
              backgroundColor: "#1a293c",
              borderColor: "#ffffff",
              borderWidth: 1,
            },
          ],
        },
        options: {
          indexAxis: "y",
          scales: {
            x: { stacked: true },
            y: { stacked: true },
          },
        },
      });
    }

    // Cleanup function
    return () => {
      // Destroy the chart instance on component unmount
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default BarCharts;

import React from "react";
import NavBar from "../components/NavBar";
import "../styles/Home.css";
import AboutUs from "../components/AboutUs";
import Services from "../components/Services";
import Connect from "../components/Connect";
import HomeText from "../components/HomeText";
import Pricing from "../components/Pricing";
import { Element } from "react-scroll";
const Home = () => {
  return (
    <div>
      <NavBar />
      <Element name="home">
        <HomeText />
      </Element>
      <Element name="services">
        <Services />
      <Pricing/>
      </Element>
      <Element name="about">
        <AboutUs />
      </Element>
      <Element name="connect">
        <Connect />
      </Element>
    </div>
  );
};

export default Home;

import React from "react";

const MemberProfileCard = ({ ePic, name, description }) => {
  return (
    <div className="member-profileCard-main">
      <div className="member-profileCard-top">
        <img src={ePic} alt="pic" id="member-profileCard-img" />
      </div>
      <div className="member-profileCard-middle">{name}</div>
      <div className="member-profileCard-bottom">
        <p id="member-profileCard-description">{description}</p>
      </div>
    </div>
  );
};

export default MemberProfileCard;

import React from 'react'
import logo from "../assets/logo.png";

import "../styles/page.css";


import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import CEO from "../assets/CEO.png";
import CTO from "../assets/CTO.png";

const AboutUS = () => {
  return (
   
<div>
               <div className='about-us2'>
                <img  src={logo} alt="" />
               </div>
                <h1 className='h1'>About Us</h1>
                <p className='p'>We are your personal assistant</p>
                <b className="signup">Signup</b>
               <div className="login-button"></div>
               <b className="login">Login</b>
               
               <div className="header-items">
                <a href="#" className="header-item1">Home</a>
                <a href="#" className="header-item2">Services</a>
                <a href="#" className="header-item3">About Us</a>
                <a href="#" className="header-item4">Connect</a>
                <a href="#" className="header-item5">Pricing</a>
                <a href="#" className="header-item6">Blogs</a>
            </div>

               <div className='white-container' ></div>
               <h1 className='sec2'>What is Logit?</h1>
               <div className='flex-container1'>
               <div className='flex-itemA'>
                <div className='para1'>
               <p>Imagine you run a gym and you are constantly juggling a million things:membership details, attendance sheets, chasing after
               overdue fees - it’s a paper nightmare! Logit is your super-organized friend who steps in and brings order to the chaos by taking care of all your boring paperwork</p>
               </div>

              <div className='para2'>
              <p><b><u>Member management made easy</u></b></p>
              <p>No more messy spreadsheets! Logit stores all your member information in one place, so you can easily find what you need.
              </p>
       
              <p><b><u>Say goodbye to attendance hassles</u></b></p>
              <p>Forget paper sign-in sheets. Members can scan a QR code to mark their attendance, saving time and resources for everyone. No more messy spreadsheets! Logit stores all your member information in one place, so you can easily find what you need.
              </p>

              <p><b><u>Fees under control</u></b></p>
              <p>Logit keeps track of upcoming and overdue fees, so you can stay on top of your finances. Plus, members can see their fees due dates too, avoiding any confusion.
              </p>

             <p><b><u>Data that makes sense</u></b></p>
            <p>Wondering who your typical member is or when the gym is busiest? Logit gives you clear reports  on things like member demographics and attendance trends. This helps you understand your members better and tailor your offerings to their needs.
             </p>

             <p><b>Think of Logit as your gym’s secret weapon, helping you run things smoothly, keeps members happy, and focus on what really matters - building a thriving fitness community.</b></p>
      </div>
      </div>
      </div>
          
      <div className='white-container' ></div>
      <h1 className='s3'>What is our vision with LOGIT?</h1>
      <div class="Flex-container2">
        <div class="Flex-item2">
        <img src={img2} alt=""/>
        </div>
        <div class="Flex-item3">
        <p>
      Imagine a world where every gym owner feels<br></br>
      empowered and in control. Logit’s vision is to be<br></br>
      the go-to solution for gym management, providing<br></br>
      the tools and the insights needed for gyms of all<br></br>
      sizes to flourish. We want to see gyms not just<br></br> 
      survive but thrive, with happy members and string<br></br> 
      communities.
      </p>
      <p><b>We see ourselves as a part of the larger movement to simplify the fitness experience for both gym owners and members.</b></p>
      
        </div>
     </div>

     <div className='white-container'></div>
      <h1 className='s4'>What is our aim with LOGIT?</h1>
      <div class="Flex-container3">
        <div className='Flex-item4'>
        <p>The whole idea behind Logit is to make running a gym feel less like a chore and more like, 
          well, running a gym. We know gym owners wear a lot of hats - trainers, receptionists, accountants,
           you name it! But paperwork and chasing after payments should not be one of them.
           </p>
        </div>
        <div className='Flex-item5'>
        <p>That’s where Logit comes in.  We want to be the ultimate gym management software sidekick, 
          super user-friendly and easy to navigate. Our goal is to see more and more gyms use Logit, '
          so owners can spend less time wrestling with spreadsheets and more time doing what they love - helping members 
          reach their fitness goals and building a strong gym community. Imagine all that freed-up time they can spend on creating 
          awesome new workout programs or giving their members that extra bit of coaching they need. Essentially, Logit is here to help gym owners 
          ditch the hassle and focus on the real magic -
           <b>making their gyms the best they can be!</b>
           </p>
        </div>
        <div className='Flex-item6'>
        <img src={img1} alt=''/>
        </div>
      </div>

      <div className='white-container'></div>
      <h1 className='S5'>The Founders</h1>
      <div class="Flex-container4">
        <div className='Flex-item7'>
        <img src={CEO} alt=""/>
        </div>
        <div className='Flex-item8'>
        <p>We believe a great gym experience shouldn’t involve confusion about fees or wondering if your attendance is being tracked.
           That’s why Logit is designed with members in mind too. It is our way of giving gym owners a helping hand, a user- friendly tool that takes care of all the busywork. 
          This way gym owners can focus on what matters most - their members and their passion for fitness. 
        We will make gym management as easy as lifting a 5 KG dumbbell after 1 month of training.</p></div>
        <div className='Flex-item9'>
          <h1>Pratham Aggarwal</h1>
        </div>
      </div>

      <div className='white-container2'></div>
     
      <div class="Flex-container4">
        <div className='Flex-item11'>
        <img src={CTO} alt=""/>
        </div>
        <div className='Flex-item12'>
        <p>Imagine a gym owner who is relaxed and smiling, not stressed about spreadsheets! That’s the future we see with Logit. We want this software to be a game-changer, making gym management a breeze. It will help them understand their members better. It’s like having a super organized assistant in your pocket, freeing you up to focus on what you do best - building a thriving gym community. 
        We will make gym management so light that even a 2.5 KG dumbbell will feel heavier.</p>
        </div>
        <div className='Flex-item13'>
          <h1>Suman Saurav</h1>
        </div>
      </div>









 </div>

  )
}

export default AboutUS
import "./App.css";
import "./styles/Login.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import GymSetup from "./pages/GymSetup";
import Attendance from "./pages/Attendance";
import MemberList from "./pages/MemberList";
import NoticeInformation from "./pages/NoticeInformation";
import OfferInformation from "./pages/OfferInformation";
import FeesCollection from "./pages/FeesCollection";
import FeeDueList from "./pages/FeeDueList";
import MemberLogin from "./MemberSite/MemberPages/MemberLogin";
import MemberHome from "./MemberSite/MemberPages/MemberHome";
import MemberSignUp from "./MemberSite/MemberPages/MemberSignUp";
import MemberDashboard from "./MemberSite/MemberPages/MemberDashboard";
import MemberAttendance from "./MemberSite/MemberPages/MemberAttendance";
import MemberPaymentInformation from "./MemberSite/MemberPages/MemberPaymentInformation";
import MemberProfile from "./MemberSite/MemberPages/MemberProfile";
import Pricing from "./components/Pricing";
import DashboardMember from "./pages/DashboardMember";
import IndividualMemberAttendance from "./components/IndividualMemberAttendance";
import IndividualMemberPayment from "./components/IndividualMemberPayment";
import ForgotPassword from "./pages/ForgotPassword";
import QrScanner from "./MemberSite/MemberPages/QrScanner";
import MemberNotice from "./MemberSite/MemberPages/MemberNotice";
import ResetPassword from "./pages/resetPassword";
import Error from "./pages/Error";
import DashboardEdit from "./pages/DashboardEdit";
import FAQ from "./pages/FAQ";
import MemberAboutUS from "./pages/MemberAboutUS";
import AboutUsPage from "./pages/AboutUsPage";

function App() {


  return (
    <>
      <Router>
        <Routes>
          {/*owner auth pages*/}
          <Route path="/" element={<Home/>} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path ="/forgotpassword" element={<ForgotPassword/>} />
          <Route path="/gymsetup" element={<GymSetup/>}/>
          <Route path="/reset-password" element={<ResetPassword/>}/>
          <Route path="/member-aboutus" element={<MemberAboutUS/>}/>
          <Route path="about-us" element={<AboutUsPage/>}/>
          {/*owner use pages*/}
          <Route path="/dashboard" element={<Dashboard/>}/> 
          <Route path="/dashboard-edit" element={<DashboardEdit/>}/> 
          <Route path="/attendance" element={<Attendance/>}/>
          <Route path="/members-information" element={<MemberList/>}/>
          <Route path="/fee-collection-information" element={<FeesCollection/>}/>
          <Route path="/notice-information" element={<NoticeInformation/>}/>
          <Route path="/offer-information" element={<OfferInformation/>}/>
          <Route path="/fee-information" element={<FeeDueList/>}/>
          <Route path="/member-information/:memid" element={<DashboardMember/>}/>
          <Route path="/member-attendance-information/:memId" element={<IndividualMemberAttendance/>}/>
          <Route path="/member-fees-information/:memId" element={<IndividualMemberPayment/>}/>
          <Route path="/faq" element={<FAQ/>}/>

          

          {/*member auth pages*/}
          <Route path="/member" element={<MemberHome/>}/>
          <Route path="/member/signup" element={<MemberSignUp/>}/>
          <Route path="/member/login" element={<MemberLogin/>}/>

          {/*owner use pages*/}
          <Route path="/member/dashboard/:memberEmail" element={<MemberDashboard/>}/>
          <Route path="/member/dashboard/:id" element={<MemberDashboard/>}/>
          <Route path="/member/attendance/:memberId" element={<MemberAttendance/>}/>
          <Route path="/member/payment/:memberId" element={<MemberPaymentInformation/>}/>
          <Route path="/member/profile/:memberId" element={<MemberProfile/>}/>
          <Route path="/member/qrScan/:memberId" element={<QrScanner/>}/>
          <Route path="/member/notice/:memberId" element={<MemberNotice/>}/>

          {/*Error Pages*/}
          <Route path="/error/:error" element={<Error/>}/>

        </Routes>
      </Router>
    </>
  );
}

export default App;

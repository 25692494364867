import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import MemberNoticeCard from "../MemberComponents/MemberNoticeCard"
import MemberDashboardComponent from "../MemberComponents/MemberDashboardComponent";

const MemberNotice = () => {
  const { memberId } = useParams();
  const [notices, setNotices] = useState([]);
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchMember();
  }, []);

  const fetchMember = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-member-by-id/${memberId}`
      );
      setMember(response.data.member);
      fetchNotices(response.data.member.gymId);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const fetchNotices = async (gymId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/gym/show-gym-notice-by-id/${gymId}`
      );
      setNotices(response.data.notices || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteNotice = (deletedNotice) => {
    const updatedNotices = notices.filter(
      (notice) => notice._id !== deletedNotice._id
    );
    setNotices(updatedNotices);
  };

  if (loading) {
    return (
      <>
        <MemberDashboardComponent memberId={memberId} />
        <p>Loading...</p>
      </>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <MemberDashboardComponent memberId={memberId} />
      <div className="mb-NoticeInformation-container">
        <div className="mb-NoticeInformation-header">
          <h2 id="mb-NoticeInformation-header-title">Notice Information</h2>
        </div>
        <div className="mb-NoticeCard-container">
          <div className="mb-NoticeInformation-row">
            <span>Issue Date</span>
            <span id="mb-NoticeInformation-row-ed">Effective Date</span>
            <span id="mb-NoticeInformation-row-details">Details</span>
            <span></span>
          </div>
          <div className="mb-NoticeInformation-main">
            {notices.length > 0 ? (
              <>
                {notices.map((notice, index) => (
                  <MemberNoticeCard
                    key={index}
                    issueDate={notice.issueDate}
                    effectiveDate={notice.effectiveDate}
                    details={notice.details}
                    onDelete={deleteNotice}
                    noticeId={notice._id}
                  />
                ))}
              </>
            ) : (
              <p>No notices found.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberNotice;

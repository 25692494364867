import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/SignUp.css";
import { Link } from "react-router-dom";
// import firebase from "firebase/app";
// import "firebase/auth";
import mainLogo from "../assets/mainLogo.png";
import tagline1 from "../assets/tagline1.png";
import tagline2 from "../assets/tagline2.png";
import tagline3 from "../assets/tagline3.png";
import tagline4 from "../assets/tagline4.png";
import tagline5 from "../assets/tagline5.png";
import tagline6 from "../assets/tagline6.png";
import tagline7 from "../assets/tagline7.png";
import tagline8 from "../assets/tagline8.png";
import Logo from "../assets/Google__G__Logo.svg";

const slideshowImages = [
  tagline1,
  tagline2,
  tagline3,
  tagline4,
  tagline5,
  tagline6,
  tagline7,
  tagline8,
];

// const firebaseConfig = {
//   apiKey: "AIzaSyD7zWegOkhRveowX3RfLTpXROvfpGRjvlM",
//   authDomain: "logit-57c4e.firebaseapp.com",
//   projectId: "logit-57c4e",
//   storageBucket: "logit-57c4e.appspot.com",
//   messagingSenderId: "1018317758615",
//   appId: "1:1018317758615:web:d1978d135c44d7f30e89d2",
//   measurementId: "G-BFT2KEEZCF",
// };

//refactoring and optimisations
const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const changeImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % slideshowImages.length
    );
  };

  useEffect(() => {
    const intervalId = setInterval(changeImage, 2000); // changing image every 2 seconds
    return () => clearInterval(intervalId);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // firebase.initializeApp(firebaseConfig);

  // const handleGoogleSignIn = async () => {
  //   try {
  //     const provider = new firebase.auth.GoogleAuthProvider();
  //     const result = await firebase.auth().signInWithPopup(provider);
  //     const user = result.user;
  //     console.log("oAuthUser SignIn", user);
  //     toast.success("User Signed Up!", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       pauseOnHover: false,
  //     });
  //     setRedirectToGymSetup(true);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const [redirectToGymSetup, setRedirectToGymSetup] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add authentication logic here, e.g., send data to a server.
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signup`,
        formData
      );

      console.log(response.data);
      toast.success("User Signed Up!", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
      // Set state to trigger redirect
      setRedirectToGymSetup(true);
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle error
      toast.error("Error submitting data", {
        position: "top-right",
        autoClose: 3000,
        pauseOnHover: false,
      });
    }
  };

  if (redirectToGymSetup) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="signup-container">
      <div className="signup-left-half">
        <div className="signup-logo">
          <a href="/">
            <img src={mainLogo} alt="Logit" id="signup-mainLogo" />
          </a>
        </div>
        <h1 id="signup-h1">
          Welcome to,
          <br /> Logit <span id="signup-for">for</span> Business
        </h1>
        <h2 id="signup-Where-we">Where we...</h2>
        <div className="slideshow">
          <img
            src={slideshowImages[currentImageIndex]}
            alt="Slideshow"
            className="signup-slideshow-image"
          />
        </div>
      </div>
      <div className="signup-right-half">
        <div className="signup-right-half-top">
          <h2 id="signup-right-half-h2">
            Sign Up <br />
            <p className="signup-b-text">
              and shake hands with your gym management partner
            </p>
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="form-group">
            <label
              htmlFor="signup-nameInput"
              id="signup-Name"
              className="signup-label"
            >
              Name
            </label>
            <input
              type="text"
              name="username"
              id="signup-nameInput"
              placeholder="Please Enter Your Name"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="signup-form-group">
            <label
              htmlFor="signup-emailInput"
              id="signup-Email"
              className="signup-label"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="signup-emailInput"
              placeholder="Please Enter Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="signup-form-group">
            <label
              htmlFor="signup-passwordInput"
              id="signup-Password"
              className="signup-label"
            >
              Password
            </label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="signup-passwordInput"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <button type="submit" id="signup-button">
            Sign Up
          </button>
        </form>
        {/**<button onClick={handleGoogleSignIn} id='googleSignin'>
          <img src={Logo} alt='Google Logo' id='googleLogo' />{" "}
          <span id='Google-text'>Continue with Google</span>
  </button>**/}
        <div className="signup-login-link">
          Already registered? <Link to="/login">Login </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
